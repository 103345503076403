.formComp {
	position: relative;
	padding-left: 24px;
	font: 400 13px/1.230769230769231 var(--font);
	text-align: left;
	cursor: pointer;
	display: inline-block;

	input {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		border: 0;
		clip: rect(0 0 0 0);
		appearance: none;
	}

	span {
		display: inline-block;
    word-break: break-word;
	}

	span::after,
	span::before {
		content: '';
		position: absolute;
		transition: all .4s ease;
		box-sizing: border-box;
	}

	span::before {
		top: 0;
		left: 0;
		width: 16px;
		height: 16px;
		border: 1px solid var(--text-gray);
	}
	span::after {
		opacity: 0;
	}

	input:checked + span {
		&::before {
			background: var(--background-green);
			border-color: var(--background-green);
		}
		&::after {
			opacity: 1;
		}
	}

	input:focus-visible + span {
		&::before {
			border-color: var(--background-green);
			box-shadow: var(--shadow-focus-green);
		}
	}
	:global(.usingKeyboard) & input:focus + span {
		&::before {
			border-color: var(--background-green);
			box-shadow: var(--shadow-focus-green);
		}
	}

	&:hover {
		span::before {
			border-color: var(--background-green-hover);
		}
		input:checked + span::before {
			background: var(--background-green-active);
			border-color: var(--background-green-active);
		}
	}

	&.disabled {
		pointer-events: none;
		cursor: none;
	}

	input[disabled] + span {
		&::before {
			opacity: .5;
		}
	}

	input[disabled]:checked + span {
		&::before {
			background: var(--background-green-active);
			border-color: var(--background-green-active);
		}
	}

	& + & {
		margin-top: 16px;
	}
}

.checkbox {
	span {
		&::before {
			border-radius: 4px;
		}
		&::after {
			top: 5px;
			left: 4px;
			width: 8px;
			height: 4px;
			border-left: 1.3px solid var(--text-white);
			border-bottom: 1.3px solid var(--text-white);
			transform: rotate(-45deg);
		}
	}
}

.radio {
	span {
		&::before {
			border-radius: 50%;
		}
		&::after {
			width: 6px;
			height: 6px;
			background: var(--background-white);
			border-radius: 50%;
			top: 5px;
			left: 5px;
		}
	}
}
