@import "/src/shared/lib/styles/config/variables";
@import "/src/shared/lib/styles/config/mixins";

.page {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  min-width: var(--min-width);
  color: var(--text-black);
  box-sizing: border-box;
}

.page__main {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-height: var(--vh);
  font: 400 var(--fs-13) var(--font);
  box-sizing: border-box;
}

.page__content {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: calc(100% - 208px);
  transition: width .4s ease;
  margin-left: auto;
}

.page__wrap {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: var(--border-divider);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--text-gray);
    border-radius: 20px;
  }
}

.page__scroller {
  display: flex;
  flex-flow: column nowrap;

  .page__inner {
    flex-basis: 100%;
  }
}
