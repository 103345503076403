@import "src/shared/lib/styles/config/mixins";

.taskCard {
	position: relative;
	background: var(--background-white);
	padding: 12px;
	border-radius: 8px;
	width: 100%;
	box-sizing: border-box;
	align-self: baseline;
	cursor: pointer;
	outline: none;
	font: 400 var(--fs-13) var(--font);
  transition: box-shadow .4s ease;

	&:hover {
		box-shadow: var(--shadow-card);
	}

	&::after {
		@include absoluteFit;
		box-shadow: inset 0 0 0 2px var(--stroke-focus);
		border-radius: inherit;
		user-select: none;
		pointer-events: none;
		transition:
			opacity var(--trans-fast);
		opacity: 0;
		content: '';
	}

	&:focus {
		outline: none;
	}

	&:focus-visible {
		box-shadow: var(--shadow-card);

		&::after {
			opacity: 1;
		}
	}

	:global(.usingKeyboard) &:focus {
		box-shadow: var(--shadow-card);

		&::after {
			opacity: 1;
		}
	}

	&.isDisabled {
		pointer-events: none;
	}
}

/* *
 * Маркер связанной задачи
 */
.linkedTaskMark {
	display: block;
	flex-shrink: 0;
	width: 16px;
	height: 16px;
	margin-bottom: 8px;
	stroke-width: 1.1;
}

/* *
 * Кнопка переноса в архив
 */
.archiveLink {
	text-align: left;
	color: var(--text-gray);
	margin-top: -2px;
	margin-left: -4px;
	z-index: 3;
  margin-bottom: 10px;

	svg {
		width: 12px;
		height: 12px;
		stroke: currentColor;

		&.loadingIcon {
			animation: circle 1s infinite linear;
		}
	}
}

.mainLink {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
	opacity: 0;
	z-index: 2;
	display: none;
}

/* *
 * Панель тегов
 */
.tagsWrap {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	position: relative;
	z-index: 1;

	&:not(:empty) + * {
		margin-top: 8px;
	}
}

.tag {
  cursor: pointer;
}

/* *
 * Инфо о задаче
 */
.title {
	display: -webkit-box;
	overflow: hidden;
	word-break: break-word;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	font: 500 var(--fs-14) var(--font);

	& + * {
		margin-top: 8px;
	}
}

/* *
 * Информация о доске
 */
.board {
	position: relative;
	z-index: 3;

	&, .btnLink {
		color: var(--text-gray);
	}
}

.boardLink {
	text-decoration: underline;
	transition: color .4s ease;
	outline: none;
  text-align: start;
  word-break: break-word;

	&:hover {
		color: var(--text-black);
	}

	&:focus-within {
		color: var(--text-black);
	}
}

/* *
 * Панель счетчиков
 */
.shortInfo {
	margin-top: 18px;
	display: flex;
	color: var(--text-gray);
	flex-wrap: wrap;
	gap: 10px;

	span {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		gap: 2px;
	}

	&:empty {
		margin-top: 0;
	}
}

.shortInfoIcon {
	width: 12px;
	height: 12px;
	stroke: currentColor;
}

.expired {
	color: var(--background-red);
}

/* *
 * Панель с кнопками
 */
.footerButtons {
	display: flex;
	flex-wrap: nowrap;
	gap: 8px;
	z-index: 4;
	position: relative;
  opacity: 0;
  height: 32px;
	margin-top: 0;
	margin-bottom: -32px;
  transition:
		margin var(--trans-fast),
		height var(--trans-fast),
		opacity .2s var(--ease);

	button {
		white-space: nowrap;
	}

	&:empty {
		display: none;
	}
}

.footerButtons.shown,
.taskCard:hover .footerButtons,

:global(.isDragging) .footerButtons,
:global(.usingKeyboard) .taskCard:focus-within .footerButtons {
	opacity: 1;
	margin-top: 20px;
	margin-bottom: 0;
	height: 32px;
	transition:
		margin var(--trans-fast),
		height var(--trans-fast),
		opacity var(--trans-fast);
}

.footerDropDown {
	width: 50%;
	padding-left: 0;
	padding-right: 0;

	& > div {
		display: block;
		width: 100%;
	}

	button {
		width: 100%;
	}
}

.footerButton {
	width: 50%;
	padding-left: 0;
	padding-right: 0;
}

@keyframes circle {
	100% { transform: rotate(360deg); }
}
