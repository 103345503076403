@import 'src/shared/lib/styles/config/mixins';

.filesMore {
	flex-shrink: 0;
	margin-left: 8px;
}

.filesMoreBtn {
	flex-shrink: 0;
	font-weight: 400;
}

.filesListScroller {
	display: flex;
	flex-flow: column nowrap;
	row-gap: 12px;
	padding: 12px;
	max-height: 204px;
	width: 362px;
	box-sizing: border-box;
	overflow-y: auto;
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	@include hideScrollbar;
}

.filesListTools {
	width: 362px;
	padding: 0 12px 12px 12px;
	box-sizing: border-box;
}