.subtaskWrap {
	display: flex;
	flex-wrap: nowrap;
	column-gap: 16px;
	justify-content: space-between;
	align-items: flex-start;
	min-height: 28px;
	margin-bottom: 4px;

	&:last-of-type {
		margin-bottom: 18px;
	}
}

.checkbox {
  user-select: none;

  input + span {
		display: inline;
		background: linear-gradient(to right, currentColor ,currentColor) left bottom 0 no-repeat;
		background-size: 0 1px;
		background-position: 0 56%;
		transition: background-position .4s ease;
	}
}

.checkboxDone {
  text-decoration: line-through;
}

.leftColumn {
  padding-top: 6px;
  display: flex;
  align-items: center;
	word-break: break-word;
}

.rightColumn {
  min-height: 28px;
	display: flex;
	column-gap: 8px;
	align-items: center;
}

.datepicker {
  border-radius: 8px;
  & > *:first-of-type {
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
	}
	& > *:last-of-type {
		border-bottom-left-radius: inherit;
		border-bottom-right-radius: inherit;
	}
}

.removeSubtask {
  width: 304px;
  border-radius: 8px;
}

.notification {
  box-sizing: border-box;
  width: 304px;
  border-radius: 8px;
}

.disabled {
  display: none;
}

.date {
  display: flex;
  justify-content: center;
}

.toolsDrop {
	/* *
	 * меньше, чем у header'а, но
	 * больше, чем у footer'а модалки
	 */
	z-index: 58;
}
