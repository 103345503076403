
/* -------------------- Storybook :: Radio -------------------- */
.story-radio {
  display: flex;
  flex-direction: column;
}

.story-2col {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
}
.story-divider {
  width: 100%;
}

.story-icons {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

