@import "src/shared/lib/styles/config/mixins";

.card {
	display: flex;
	flex-flow: row nowrap;
	column-gap: 16px;
	position: relative;
	padding: 8px;
	background: var(--background-light-gray);
	border-radius: 10px;
}

.entity {
	flex-shrink: 0;
	width: 250px;
}

.content {
	display: flex;
	flex-flow: column nowrap;
	row-gap: 8px;
	padding: 4px 4px 4px 0;
	width: 100%;
}

/*
 * Head
 */
.head {
	display: flex;
	flex-flow: row nowrap;
	column-gap: 12px;
}

.title {
	width: 100%;
}

.marker {
	display: inline-block;
	position: absolute;
	margin: 0.46em 0 0 4px;
	width: 8px;
	height: 8px;
	background: var(--background-green);
	border-radius: 50%;
	content: '';

	&:not(.active) {
		display: none;
	}
}

.date {
	margin-top: 3px;
	flex-shrink: 0;
	color: var(--text-gray);
	white-space: nowrap;
	text-align: right;
}

/*
 * Content
 */
.message {
	position: relative;
}

/*
 * Buttons
 */
.buttons {
	display: flex;
	column-gap: 8px;
	align-items: center;
	margin-top: 8px;

	&:empty {
		margin-top: 0;
	}
}

.button {
	white-space: nowrap;
}

.button:not(.buttonWide) {
	padding-left: 8px !important;
	padding-right: 8px !important;
	min-width: 117px;

	&.buttonRetry {
		min-width: 130px;
	}
}

.buttonWithArrow {
	min-width: 123px;
}

.buttonLinkWrap {
	margin-left: auto;
}

.buttonLink {
	display: flex;
	align-items: center;
	position: relative;
	height: 32px;
	white-space: nowrap;
	color: var(--text-gray);

	&:hover {
		color: var(--text-black);
	}

	&::after {
		position: absolute;
		left: -8px;
		right: -8px;
		top: 0;
		bottom: 0;
		box-shadow: inset 0 0 0 2px var(--stroke-focus);
		border-radius: 8px;
		user-select: none;
		pointer-events: none;
		transition:
			opacity var(--trans-fast);
		opacity: 0;
		content: '';
	}

	&:focus {
		outline: none;
	}
	&:focus-visible::after {
		opacity: 1;
	}
	:global(.usingKeyboard) &:focus::after {
		opacity: 1;
	}

	span {
		text-decoration: underline;
	}
}

.acceptNotice {
	margin-left: 24px;
	white-space: nowrap;

	&.leftAuto {
		margin-left: auto;
	}
}

.createMessage {
	margin-left: 16px;
}

.readMessage {
	margin-left: 28px;
}

.reassignConfirmDropdown {
	width: 304px;
	border-radius: 8px;
}
