.dropDown {
	position: static;
	margin-left: 6px;

	button.dropDownBtn {
		height: 32px;
		padding-left: 6px;
		padding-right: 6px;
		font-weight: 400;
		white-space: nowrap;
	}
}

.dropDownBox {
	z-index: 70;
}

.dropDownBoxContent {
	width: 320px;
	padding: 12px;
	background: var(--background-light-gray);
}
