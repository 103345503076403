.content {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
  font: 400 var(--fs-13) var(--font);
  color: var(--text-black);
  border-radius: 0 6px 6px 6px;
  box-sizing: border-box;
  width: 100%;

  &_default {
    padding: 8px;
    background: var(--background-gray);

    a {
      color: var(--text-green);

      &:hover {
        color: var(--background-green-hover);
      }
    }
  }

  &_edit {
    padding: 8px 8px 12px;
    border: 1px solid var(--border-divider);
    background: var(--background-white);
  }
}

.contentError {
  border: 1px solid var(--border-error);
  margin-bottom: 8px;
}

.textarea {
  font: 400 var(--fs-13) var(--font);
  color: var(--text-black);
  width: 100%;
  resize: none;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);;
  }
}

.files {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  button.footer__attach {
    padding: 0 !important;
    width: 24px;

    svg {
      width: 16px;
      max-width: 16px;
      height: 16px;
    }
  }
}

.attachmentEditWrap {
  display: flex;
  align-items: center;
  column-gap: 8px;
  max-width: 100%;

  button:first-of-type {
    min-width: 0;
  }

  button + button {
    flex-shrink: 0;
  }
}

.errorMessage {
  color: var(--text-error);
}
