.controlsWrap {
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-shrink: 0;

  button {
    font-weight: 400;
  }
}

.confirmModal {
  z-index: 58;
  width: 304px;
}

.control {
  display: flex;
  padding: 2px 4px;
  align-items: center;
  column-gap: 4px;
  font: 400 var(--fs-13) var(--font);

  svg {
    display: block;
    fill: none;
    stroke: currentColor;
    stroke-width: 1.47;
    stroke-linecap: round;
    stroke-linejoin: round;
    width: 12px;
    height: 12px;
  }
}
