.popupWrapper {
  width: 304px;
  background: var(--background-white);
  box-shadow: 0 429px 500px rgba(0, 13, 57, 0.0028182), 0 190.268px 244.125px rgba(0, 13, 57, 0.00505165), 0 98.0524px 160.197px rgba(0, 13, 57, 0.00789993), 0 48.5968px 105.649px rgba(0, 13, 57, 0.013994), 0 20.7088px 64.163px rgba(0, 13, 57, 0.0472), 0 4.94px 29.8776px rgba(0, 13, 57, 0.12);
  border-radius: 8px;

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--border-divider);
    font: 500 var(--fs-14) var(--font);

    .closeButton, .backButton {
      position: absolute;
      top: 8px;
    }
    .closeButton {
      right: 12px;
    }
    .backButton {
      left: 12px;
    }

  }

  &__main {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 8px 12px 12px;
    font: 400 var(--fs-13) var(--font);

    .buttons {
      display: flex;
      column-gap: 8px;
      align-items: center;
    }
    .button {
      width: 100%;
    }
  }
}
