.modal {
  position: fixed;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--shadow-curtain);
  pointer-events: none;
}

.overlayAnimation {
  animation: fadeInOverlay 400ms forwards;
}

.scroller {
  height: 100%;
  padding: 0 24px;
  overflow-y: scroll;
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
}

.wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  pointer-events: none;

  .leftPosition & {
    align-items: flex-start;
  }
  .rightPosition & {
    align-items: flex-end;
  }
}

.box {
  position: relative;
  z-index: 2;
  margin: 50px 0;
  background: var(--background-light-gray);
  box-shadow: var(--shadow-card);
  pointer-events: auto;
}

.boxAnimation {
  animation: fadeIn 400ms forwards; 
} 

.close {
  z-index: 11;
  width: 32px;
  position: absolute;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 40px;
    height: 40px;
  }
}

.sm {
  border-radius: 8px;
  background: var(--background-white);

  .close {
    right: 9px;
    top: 8px;
  }
}

.md {
  border-radius: 12px;
  background: var(--background-light-gray);

  .close {
    top: 24px;
    right: 32px;
  }
}

.transparentBackground {
  background: transparent;
  box-shadow: none;
}

.header {
  position: sticky;
  top: 0;
  z-index: 60;
  background: var(--background-light-gray);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  box-shadow: 0 4px 14px rgba(0, 13, 57, 0);
  transition:
    border-radius var(--trans-fast),
    box-shadow var(--trans-medium);
}

.headerShadow {
  border-radius: 0;
  box-shadow: var(--shadow-header);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    pointer-events: none;
  }
  to {
    opacity: 0;
    pointer-events: auto;
  }
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOutOverlay {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* *
 * Submodal
 */
.isSubmodal {
  right: 0;
  width: auto;

  .scroller {
    padding-left: 28px !important;
    padding-right: 16px !important;
    width: 100%;
    min-width: fit-content;
    float: right;
    overflow-x: hidden;
  }

  .wrap {
    pointer-events: auto;
    align-items: flex-start;

    &.inverse {
      align-items: flex-end;
    }
  }

  .box {
    box-shadow:
      0 20px 48px rgba(0, 13, 57, 0.0472),
      0 5px 24px rgba(0, 13, 57, 0.12);
  }
}
