.tag {
	position: relative;
	font-size: 13px;
	line-height: 1;
	padding: 4px 8px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	column-gap: 4px;
	outline: none;
	flex-wrap: nowrap;
	max-width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	cursor: default;

	svg,
	span {
		position: relative;
		z-index: 1;
	}
	svg {
		flex-shrink: 0;
		stroke: var(--text-black);
		width: 12px;
		height: 12px;
		display: inline-block;
	}
	span {
		white-space: nowrap;
		display: flex;
		align-items: center;
		column-gap: 4px;
		line-height: 16px;
		position: relative;
		z-index: 2;

		& + svg {
			position: relative;
			top: 1px;
		}
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	&::before {
		background: var(--background-gray);
		border-radius: 5px;
		transition: opacity .4s ease;
	}
	&::after {
		z-index: -2;
		background: var(--background-white);
		border-radius: 6px;
	}
}

.gray::before {
	background: var(--tag-grey);
}
.red::before {
	background: var(--tag-red);
}
.pink::before {
	background: var(--tag-pink);
}
.green::before {
	background: var(--tag-green);
}
.purple::before {
	background: var(--tag-purple);
}
.orange::before {
	background: var(--tag-orange);
}
.blue::before {
	background: var(--tag-blue);
}
.light-blue::before {
	background: var(--tag-light-blue);
}
.yellow::before {
	background: var(--tag-yellow);
}

button.tag {
	&:focus {
		outline: none;
	}

	span.text {
		display: flex;
		min-width: 0;
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;

		span {
			display: block;
			min-width: 0;
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

.maxWidth span {
	display: block;
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
}

.hoverEffect {
	cursor: pointer;

	&:hover::before {
		opacity: .75;
	}

	&:focus-visible {
		outline: 2px solid var(--stroke-focus);
	}
	:global(.usingKeyboard) &:focus {
		outline: 2px solid var(--stroke-focus);
	}
}

.noHoverEffect {
	cursor: default;

	.cross {
		cursor: pointer;
	}
}

.cross {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 20px;
	height: 20px;
	margin: -2px -4px -2px 0;

	svg {
		pointer-events: none;
	}

	&:focus-visible {
		outline: 2px solid rgba(0, 0, 0, .2);
		border-radius: 5px;
	}
	:global(.usingKeyboard) &:focus {
		outline: 2px solid rgba(0, 0, 0, .2);
		border-radius: 5px;
	}
}

.loading {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 20px;
	height: 20px;
	margin: -2px -4px -2px 0;

	svg {
		pointer-events: none;
		animation: circle 1s infinite linear;
	}
}

@keyframes circle {
	100% { transform: rotate(360deg); }
}
