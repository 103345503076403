/*
 * Functions
 */
@function toRGB($color) {
  @return "rgb(" + red($color) + ", " + green($color) + ", " + blue($color)+ ")";
}

/*
 * Gaps
 */
@mixin containerGaps {
  padding-left: $gap;
  padding-right: $gap;
}

/*
 * Sizes
 */
@mixin vp-full {
  height: 100vh;

  @supports (height: var(--vh)) {
    height: var(--vh);
  }
}

@mixin vp-full-min {
  min-height: 100vh;

  @supports (min-height: var(--vh)) {
    min-height: var(--vh);
  }
}

@mixin vw-full {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @supports (width: var(--vw)) {
    width: var(--vw);
    margin-left: calc(var(--vw) / -2);
    margin-right: calc(var(--vw) / -2);
  }
}

@mixin absoluteFit {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@mixin absoluteFitIndent($indent) {
  position: absolute;
  left: $indent;
  top: $indent;
  right: $indent;
  bottom: $indent;
}

/*
 * Fade
 */
@mixin fadeable {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition:
    opacity var(--trans),
    visibility var(--trans);
}

@mixin fadeable-in {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

/*
 * Blocks with scroll
 */
@mixin vertical-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin horizontal-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

/*
 * Media queries
 */
@mixin to($to) {
  @media (max-width: $to - 1) {
    @content;
  }
}
@mixin from($from) {
  @media (min-width: $from) {
    @content;
  }
}
@mixin range($from, $to) {
  @media (min-width: $from) and (max-width: $to - 1) {
    @content;
  }
}
