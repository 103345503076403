.filesAttach {
	flex-shrink: 0;
	margin-left: 8px;
}

.filesAttachBtn {
	flex-shrink: 0;
}

.filesAttachDrop {
	min-width: 304px;
	box-sizing: border-box;
}

.filesAttachMenu {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	row-gap: 12px;
	padding: 12px 8px;
}

.actionButton {
	padding: 2px 4px;
	height: 20px;
	width: 100%;
	justify-content: flex-start;
	font-weight: 400;
}

.actionButtonLoading {
	color: var(--text-black);
	animation: circle 1s infinite linear;
}

@keyframes circle {
	100% { transform: rotate(360deg); }
}
