$hd:     			1440px          	!default;
$fhd:    			1920px         		!default;

$from-hd:     $hd - 20          !default;
$to-hd:       $from-hd - 1      !default;

$from-fhd:    $fhd - 20         !default;
$to-fhd:      $from-fhd - 1     !default;

$gap: 8px;
$gapSM: 16px;
