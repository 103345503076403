.drop {
	margin-left: -370px;
}

.dropBox {
	z-index: 30;
	min-width: 260px;
	min-height: 434px;
	background: var(--background-white);
	border-radius: 8px;
	box-sizing: border-box;

	& > *:first-of-type {
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
	}
	& > *:last-of-type {
		border-bottom-left-radius: inherit;
		border-bottom-right-radius: inherit;
	}

	&RemoveDate {
		min-height: initial;
	}
}

.editableDateTag {
	cursor: pointer;
}

.maxWidthBtn {
  max-width: 165px;
  overflow: hidden;
}
