.row {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	column-gap: 16px;
	align-items: center;
  box-sizing: border-box;

	&.wide {
		flex-flow: column nowrap;
    align-items: baseline;
	}
}

.col {
	display: block;
}

.title {
	flex-shrink: 0;
	min-width: 150px;
	white-space: nowrap;

	.wide & {
		padding-bottom: 14px;
	}
}

.left {
	display: flex;
	flex-direction: row-reverse;
	align-items: baseline;
	width: 100%;
	min-width: 0;

	.wide & {
		flex-direction: row;
		max-width: 100%;
		min-width: 100%;
	}
}

.left_flex {
	display: flex;
	column-gap: 8px;
	align-items: center;
}
