.headerShadowWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: -40px;
  overflow: hidden;
  pointer-events: none;
}

.headerShadow {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 40px;
  box-shadow: var(--shadow-header);
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition:
    opacity var(--trans-medium);

  &.isShown {
    opacity: 1;
  }
}
