.changeSubtaskTitle {
  width: 362px;
  border-radius: 8px;
}

.body {
  margin-bottom: 32px;
}

.fieldset {
  display: flex;
  column-gap: 8px;
  max-width: 100%;
  min-width: 0;

  & ~ & {
    margin-top: 16px;
  }

  .legend {
    margin-bottom: 8px;
  }
}
