@import "src/shared/lib/styles/config/typography";

.textareaWrapper {
  position: relative;
  width: 100%;
}

.modalTextarea {
  display: block;
  position: relative;
  color: var(--text-black);
  width: 100%;
  resize: none;
  font-family: var(--font);
}

.without-border {
  padding: 0;
  border: none;
  background: transparent;
}

.with-border {
  border: 1px solid var(--border-divider);
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 8px;

  &:focus {
    outline: none;
    border-color: var(--background-green);
  }
}

.header-24-bold {
  @include header-24-bold;
  min-height: 32px;

  &::placeholder {
    opacity: 0.2;
  }
}

.text-13-regular {
  @include text-13-regular;
  min-height: 20px;
  line-height: 20px;

  &::placeholder {
    opacity: 1;
    color: var(--text-gray);
  }
}

.errorTextarea {
  border-color: var(--border-error);
}

.separator {
  margin-top: 15px;
}

.separatorError {
  background-color: var(--border-error);
}

.error-header-24-bold {
  @include text-14-medium;
}

.error-text-13-regular {
  font-size: 11px;
}

.error {
  margin-top: 8px;
  color: var(--text-error);
}
