@import "src/shared/lib/styles/config/typography";
@import "src/shared/lib/styles/config/mixins";

.listItem {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	column-gap: 8px;
	cursor: default;
}

.listItemContent {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	column-gap: 8px;
	margin: 0 -4px;
	padding: 2px 4px;
	height: auto;
	width: 100%;
	min-width: 50%;
}

.listItemText {
	display: flex;
	flex-flow: row nowrap;
	column-gap: 4px;
	min-width: 50%;
  width: 100%;
}

button.listItemContent {
	@include text-13-medium;

	border-radius: 4px;

	&:hover,
	&:active{
		background: var(--background-light-gray);
	}

	&:focus-visible {
		outline: 2px solid var(--stroke-focus);
	}
	:global(.usingKeyboard) &:focus {
		box-shadow: 0 0 0 2px var(--stroke-focus);
	}
}

.listItemColorDot {
	flex-shrink: 0;
	width: 16px;
	height: 16px;
	border-radius: 50%;
}

.listItemString {
  display: inline-block;
	width: 266px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.listItemCheck {
	flex-shrink: 0;
	width: 16px;
	height: 16px;
	stroke: var(--text-green);
}

.listItemTools {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	flex-shrink: 0;
	column-gap: 8px;

	.listItemToolsToggle {
		width: 20px;
		height: 20px;
		padding: 0;
		overflow: visible;

		&:before {
			@include absoluteFitIndent(-6px);
			content: '';
		}
	}
}

.listItemToolsDropMenu {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	row-gap: 8px;
	width: 180px;
	padding: 12px 8px;
	background: var(--background-white);
	border-radius: inherit;
	box-sizing: border-box;
}

.listItemToolsDropMenuItem {
	padding: 2px 4px;
	height: 20px;
	width: 100%;
	justify-content: flex-start;
	font-weight: 400;
}

