.toggle {
  display: inline-block;
  width: 32px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    appearance: none;
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background: var(--background-gray);
    border-radius: 16px;
    transition:
      background var(--trans-fast),
      box-shadow var(--trans-fast);

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 14px;
      height: 14px;
      background: var(--background-white);
      border-radius: 50%;
      transition: transform .4s ease;
    }
  }

  input:focus-visible + span {
    background: var(--background-gray-active);
    box-shadow: 0 0 0 2px var(--background-gray);
  }
  :global(.usingKeyboard) & input:focus + span {
    background: var(--background-gray-active);
    box-shadow: 0 0 0 2px var(--background-gray);
  }

  input:checked + span {
    background: var(--background-green);

    &::before {
      transform: translateX(16px);
    }
  }

  input:checked:focus-visible + span {
    background: var(--background-green-active);
  }
  :global(.usingKeyboard) & input:checked:focus + span {
    background: var(--background-green-active);
  }

  &:hover {
    span {
      background: var(--background-gray-hover);
    }
    input:checked + span {
      background: var(--background-green-hover);
    }
  }
}
