@import 'src/shared/lib/styles/config/mixins';

.search {
  box-sizing: border-box;
  width: 362px;
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 16px 12px 12px;
}

.title {
  text-align: center;
}

.separator {
  margin-top: 16px;
  width: calc(100% + 24px);
  margin-left: -12px;
}

.searchInput {
  margin: 0 -12px;
  padding: 16px 12px 12px;
  background-color: var(--background-white);
  box-shadow: var(--shadow-initial);
  transition: box-shadow var(--trans-fast);
  clip-path: inset(0 0 -100% 0);

  &.withShadow {
    box-shadow: var(--shadow-header);
  }
}

.searchList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: 400px;
  margin: 0 -12px -12px 0;
  padding: 0 12px 12px 0;
  overflow-y: auto;
  backface-visibility: hidden;

  @include vertical-scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  :global {
    .ps__rail-y {
      margin: 6px 4px 6px 0;
      height: calc(100% - 12px) !important;
      width: 4px;
    }
    .ps__thumb-y {
      width: 4px;
    }
  }
}
