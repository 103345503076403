.taskRow {
  min-height: auto;
}

.subtasks_col {
	color: var(--text-black);
	display: flex;
	flex-direction: row;
	column-gap: 2px;
	position: relative;
	top: 2px;

	svg {
		width: 16px;
		height: 16px;
		stroke: var(--text-green);
	}
}

.subtaskProgress {
	margin-top: 16px;
	margin-bottom: 16px;
	background: var(--border-divider);
	border-radius: 3px;
	height: 3px;
	position: relative;

	span {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: var(--background-green);
		height: 100%;
		border-radius: 3px;
	}
}

.createSubtask {
  margin-top: 16px;
}

.buttons {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsColumn {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.dateTag {
	cursor: pointer;
}

.datepicker {
  border-radius: 8px;
  & > *:first-of-type {
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
	}
	& > *:last-of-type {
		border-bottom-left-radius: inherit;
		border-bottom-right-radius: inherit;
	}
}
