.file {
  display: inline-flex;
  column-gap: 8px;
  max-width: 100%;

  &_card {
    padding: 4px 8px 4px 4px;
    background: var(--background-gray);
    border-radius: 6px;
  }

  &:focus-visible {
    outline: 2px solid var(--stroke-focus);
    border-radius: 5px;
  }
  :global(.usingKeyboard) &:focus {
    box-shadow: 0 0 0 2px var(--stroke-focus);
    border-radius: 5px;
  }
}

.icon {
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
  flex-shrink: 0;
  color: var(--text-white);
  background: var(--background-green);
  transition: background var(--trans-fast);

  svg {
    display: block;
    fill: none;
    stroke: currentColor;
    stroke-width: 1.47;
    stroke-linecap: round;
    stroke-linejoin: round;
    width: 16px;
    height: 16px;
  }

  .file:hover & {
    background: var(--background-green-hover);
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  min-width: 0;

  &__name {
    color: var(--text-black);
    font: 500 var(--fs-13) var(--font);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__size {
    color: var(--text-gray);
    font: 500 var(--fs-13) var(--font);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
