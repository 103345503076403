@import "src/shared/lib/styles/config/typography";

.datepicker {
  position: relative;
  width: 238px;
  padding: 12px;
  background-color: var(--background-white);
}

.group_dates {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.datepicker_wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 16px 0 8px;
  min-height: 204px;
}

.separator {
  margin: 0 -12px;
  padding: 0 12px;
}

.switches {
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.switch {
  display: flex;
  justify-content: space-between;
}

.switch_title,
.reminder_title {
  @include text-13-medium;
}

.reminder {
  margin: 14px 0;
  display: flex;
  justify-content: space-between;
}

.reminder_drop {
  position: static;
}

.reminder_dropToggle {
  margin: -4px;

  svg {
    fill: currentColor;
    stroke: none;
    transform: rotate(90deg);
  }
}

.reminder_dropBox {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  min-width: 180px;
  min-height: 128px;
  padding: 12px 8px;
  background: var(--background-white);
  border-radius: 8px;
  box-sizing: border-box;
}

.reminder_dropAction {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 20px;
  font-weight: 400;

  span {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
  }

  time {
    color: var(--text-gray);
  }
}

.actionButtons {
  margin-top: 11px;
  display: flex;
  column-gap: 8px;
}
