.listItem {
  display: flex;
  padding-left: 2px;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  column-gap: 8px;
}

.listItem:first-child {
  margin-top: 2px;
}

.userInfo {
  max-width: 240px;
}

.button {
  flex-shrink: 0;
}

.notification {
  width: 304px;
  margin-top: -32px;
}

.notificationContent {
  width: 100%;
}