@import "mixins";

/* * * Selection * * */
::selection {
  color: var(--text-white);
  background: var(--background-black);
}

/* * * Visibility * * */
.hidden {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.invisible {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  appearance: none;
}

/* * * Alignment * * */
.aling-left {
  text-align: left;
  justify-content: flex-start;
}
.aling-center {
  text-align: center;
  justify-content: center;
}
.aling-right {
  text-align: right;
  justify-content: flex-end;
}
.aling-justify {
  text-align: justify;
  justify-content: space-between;
}

/* * * Text * * */
.nowrap-text {
  white-space: nowrap;
}
.underline-text {
  text-decoration: underline;
}
.uppercase-text {
  text-transform: uppercase;
}

/* * * Icons * * */
.inline-svg-icon {
  position: relative;
  top: .125em;
  flex-shrink: 0;
  height: 1em;
  width: 1em;
  fill: currentColor;
  transition: fill .4s;

  &--block {
    display: block;
    top: 0;
  }
}

.svg-path {
  stroke: none !important;
  fill: currentColor !important;
}

.svg-spin {
  animation: circle 1s infinite linear;
}

@keyframes circle {
  100% { transform: rotate(360deg); }
}

/* * * Dimensions * * */
.vp-full {
  height: 100vh;

  @supports (height: var(--vh)) {
    height: var(--vh);
  }
}

.vp-full-min {
  min-height: 100vh;

  @supports (min-height: var(--vh)) {
    min-height: var(--vh);
  }
}

.vw-full {
  @include vw-full;
}

/* * * Decor * * */
.text-stroke {
  -webkit-text-stroke: 1px currentColor;
  -webkit-text-fill-color: transparent;
}

/* * * Overflow * * */
.overflow-clip { overflow: hidden; }

/* * * Grecaptcha badge * * */
.grecaptcha-badge {
  opacity: 0;
}

/* * * Prompt text with hints * * */
.promptText {
  z-index: 1000;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  padding: 2px 4px;
  font-size: 11px;
  line-height: 16px;
  white-space: nowrap;
  color: var(--text-white);
  background: var(--background-tooltip-black);
  border: 1px solid var(--background-black);
  border-radius: 2px;
  opacity: 0;
  transition: opacity .4s ease .1s;
  text-align: left;
}
