.header {
	padding: 24px 32px 16px 32px;
	z-index: 2;
	display: flex;
	flex-wrap: nowrap;
	gap: 16px;
	justify-content: space-between;
	align-items: center;
}

.headerLeft {
  display: flex;
  column-gap: 8px;

	[datatype="drop"] {
		width: auto;
	}
}

.headerRight {
  padding-right: 40px;
}

.kebabMenu {
	min-width: 32px;
	max-width: 32px;
}

.headerNoticeLabel {
	margin-left: 6px;
	white-space: nowrap;
}

.body {
	position: relative;
	padding: 8px 32px 24px;
	font: 400 var(--fs-13) var(--font);

	[datatype="tag"] {
		padding: 5px 8px;
	}
}

.colWrapWide {
  padding: 16px 0;
	border-bottom: 1px solid var(--border-divider);
}

.colWrap {
	padding: 14px 0;
	border-bottom: 1px solid var(--border-divider);
}

.colWrap {
	&:last-of-type {
		border-bottom: none;
		padding-bottom: 0;
	}
}

.colTitle {
	font: 700 var(--h3) var(--font);
}
.description .colTitle {
	padding-bottom: 12px;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	column-gap: 16px;
	align-items: baseline;
}
.colTitle {
	min-width: 150px;
	max-width: 150px;
}
.left {
	max-width: 330px;
	min-width: 330px;

	display: flex;
	flex-direction: row-reverse;
	align-items: baseline;
}

.subtasks {
	display: flex;
	flex-direction: column;
}

.subscribe {
	display: flex;
	flex-flow: column nowrap;
	row-gap: 16px;
	padding: 16px 0 2px;
	border-bottom: 0;
}

.subscribeControl {
	& ~ & {
		margin-top: 0;
	}
}

.cross {
	input + span {
		background-size: 100% 1px;
	}
}

.footer {
	position: sticky;
	bottom: 0;
	z-index: 56;
	margin-top: -8px;
	padding: 12px 32px;
	background: var(--background-white);
	border-top: 1px solid var(--border-divider);
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}
