@import "src/shared/lib/styles/config/typography";

.linkedTask {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 8px;
  min-height: 28px;
  margin-bottom: 4px;

	&:last-of-type {
		margin-bottom: 18px;
	}
}

.checkbox {
  margin: 4px 0;
  user-select: none;
}

.checkboxDone {
  text-decoration: line-through;
}

.leftColumn {
  display: flex;
  align-items: flex-start;
  align-self: center;
}

.rightColumn {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.taskName {
  display: flex;
  align-items: flex-start;
  column-gap: 4px;
}

.icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin: 4px 0;

  svg {
    width: 100%;
    height: 100%;
    stroke-width: 1.1;
  }
}

.status {
  flex-shrink: 0;
  margin-right: 4px;
}

.link {
  align-self: center;
  color: var(--text-green);
  @include text-13-regular;
  word-break: break-word;

  &:focus-visible {
    outline: 2px solid var(--stroke-focus);
    border-radius: 2px;
  }
  :global(.usingKeyboard) &:focus {
    outline: 2px solid var(--stroke-focus);
    border-radius: 2px;
  }

  &:hover {
    color: var(--background-green-hover);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}

.date {
  width: 84px;
  display: flex;
  justify-content: center;

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
}

.tools {
  box-sizing: border-box;
  width: 304px;
  background-color: var(--background-white);
  border-radius: 8px;
}

.notification {
  box-sizing: border-box;
  width: 304px;
  border-radius: 8px;
}
