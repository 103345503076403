@import "src/shared/lib/styles/config/typography";
@import "src/shared/lib/styles/config/mixins";

.item {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	column-gap: 8px;
	cursor: default;
}

.text {
	display: flex;
	flex-flow: row nowrap;
	column-gap: .25em;
	min-width: 0;
}

.name {
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.tools {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	flex-shrink: 0;
	column-gap: 8px;
}

.toolsBtn {
	width: 20px;
	height: 20px;
	padding: 0;
	overflow: visible;

	&:before {
		@include absoluteFitIndent(-6px);
		content: '';
	}
}
