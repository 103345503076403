@import 'src/shared/lib/styles/config/mixins';

.select {
  position: relative;
}

.selectDrop {
  z-index: 65 !important;
}

.selectDropContent {
  display: flex;
  flex-flow: column nowrap;
}

.button {
  justify-content: space-between;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_md {
    padding: 8px 12px;
    width: 156px;
  }

  &_sm {
    padding: 4px 8px;
    width: 124px;
  }

  & .loadingIcon {
    animation: circle 1s infinite linear;
  }
}

.optionsList {
  display: flex;
  flex-flow: column nowrap;
  overflow-y: scroll;
  max-height: 491px;
  background: var(--background-white);
  border-radius: 8px;
  @include hideScrollbar;

  &.withoutBorders {
    padding: 8px 0;
    row-gap: 8px;
  }
}

.additionalDrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 8px;
  background: inherit;
  border-radius: inherit;
  box-shadow: var(--shadow-dropdown);
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  color: var(--text-black);
  padding: 12px 12px;
  box-sizing: border-box;
  width: 260px;
  min-width: 100%;
  font: 500 var(--fs-13) var(--font);
  outline: none;
  cursor: pointer;

  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    stroke: var(--text-green);
  }

  &:hover {
    color: var(--text-green);
  }

  &:focus-visible {
    color: var(--text-green);
  }

  :global(.usingKeyboard) &:focus {
    color: var(--text-green);
  }

  .withoutBorders & {
    padding: 8px 12px;
  }
}

.optionsList:not(.withoutBorders) {
  .option + .option {
    border-top: 1px solid var(--border-divider);
  }
}

.optionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterBlock {
  position: relative;
  padding: 12px;
  background: var(--background-white);
  border-radius: 8px 8px 0 0;

  & + * {
    padding-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.filterBlockShadow {
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  height: 30px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition:
    opacity var(--trans-medium);

  &:before {
    position: absolute;
    left: 0;
    top: -100%;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-header);
    content: '';
  }

  .withShadow & {
    opacity: 1;
  }
}

@keyframes circle {
  100% { transform: rotate(360deg); }
}
