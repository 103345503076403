.input {
  box-sizing: border-box;
  display: block;
  padding: 0;
  border-radius: 6px;
  position: relative;
  line-height: 1;
  font-size: 13px;
  width: 100%;

  input {
    display: block;
    width: 100%;
    line-height: inherit;
    font-size: inherit;
    border-radius: 6px;
    border: 1px solid var(--border-divider);
  }

  textarea {
    display: block;
    width: 100%;
    font: inherit;
    border-radius: 6px;
    border: 1px solid var(--border-divider);
    resize: none;
  }

  &.light {
    input, textarea {
      background: var(--background-light-gray);
      border-color: var(--background-light-gray);
    }

    input:focus, textarea:focus {
      background: var(--background-white);
    }
  }

  &.dark {
    input, textarea {
      background: var(--background-gray);
      border-color: var(--background-gray);
    }

    input:focus, textarea:focus {
      background: var(--background-light-gray);
    }
  }

  &.light-gray {
    input, textarea {
      background: var(--background-light-gray);
      border-color: var(--background-light-gray);
    }

    input:focus, textarea:focus {
      background: var(--background-light-gray);
    }
  }

  input:focus, textarea:focus {
    border: 1px solid var(--background-green);
  }

  small {
    color: var(--text-error);
    position: absolute;
    top: calc(100% + 4px);
    left: 8px;
  }

  :global(.has-error) & input,
  :global(.has-error) & textarea {
    border-color: var(--border-error);
  }
}

.small,
.small-helper::after {
  padding: 4px 12px;
}

.normal,
.normal-helper::after {
  padding: 8px 8px 7px;
}

.withIcon {
  position: relative;

  input, textarea {
    padding-left: 31px;
  }

  svg {
    position: absolute;
    top: calc(50% - 8px);
    left: 8px;
    width: 16px;
    height: 16px;
    stroke: var(--text-gray);
  }
}

.error {
  input {
    border-color: var(--border-error);
    &:focus {
      border-color: var(--border-error);
    }
  }
}

/*
 * Многострочный инпут
 */
.inputMultirow {
  textarea {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    font-size: 13px;
    line-height: 15px;
  }

  &::after {
    display: block;
    font-size: 13px;
    line-height: 15px;
    white-space: pre-wrap;
    border: 1px solid transparent;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    content: attr(data-replicated) " ";
  }
}
