.btn {
	display: inline-flex;
	align-items: center;
	flex-wrap: nowrap;
	column-gap: 4px;
	justify-content: center;
	vertical-align: top;
	position: relative;
	text-align: center;
	text-decoration: none;
	box-sizing: border-box;
	overflow: hidden;
	transition:
		box-shadow .4s,
		color .4s,
		background .4s;
	cursor: pointer;
	font-size: 13px;
	font-weight: 500;

	svg {
		stroke: currentColor;
		fill: none;
	}

	&:focus-visible {
		outline: none;
		box-shadow: none;
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}

	&.isLoading,
	&[disabled],
	&.is-disabled, {
		pointer-events: none;
		cursor: default;
	}
}

.text {
	position: relative;
}

/* * * Sizes * * */
.md {
	border-radius: 6px;
	height: 32px;
	padding: 8px 24px;

	svg {
		min-width: 16px;
		max-width: 16px;
		height: 16px;
	}
}
.sm {
	border-radius: 5px;
	height: 24px;
	padding: 4px 8px;

	svg {
		min-width: 12px;
		max-width: 12px;
		height: 12px;
		stroke-width: 1.47;
	}
}
.onlyIcon {
	&.md {
		padding-left: 8px;
		padding-right: 8px;
	}
	&.sm {
		padding: 6px;
	}
}

/* * * Designs * * */
.no-style {
	padding: 0;
	height: auto;
}
.filled {
	&.primary {
		color: var(--text-white);
		background: var(--background-green);

		&:hover {
			background: var(--background-green-hover);
		}

		&:active {
			background: var(--background-green-active);
		}

		&:focus-visible {
			outline: 2px solid var(--stroke-focus);
			outline-offset: 0;
		}
		:global(.usingKeyboard) &:focus {
			box-shadow: 0 0 0 2px var(--stroke-focus);
		}

		&[disabled],
		&.is-disabled, {
			opacity: .5;
		}
	}

	&.secondary {
		color: var(--text-black);
		background: var(--background-gray);

		&:hover,
		&:active {
			background: var(--background-gray-hover);
		}

		&:focus-visible {
			outline: 2px solid var(--stroke-focus);
			outline-offset: 0;
		}
		:global(.usingKeyboard) &:focus {
			box-shadow: 0 0 0 2px var(--stroke-focus);
		}

		&[disabled],
		&.is-disabled, {
			color: var(--text-gray);
			background: var(--background-gray);
		}
	}

	&.danger {
		color: var(--text-white);
		background: var(--background-red);

		&:hover {
			background: var(--background-red-hover);
		}

		&:active {
			background: var(--background-red-active);
		}

		&:focus-visible {
			outline: 2px solid var(--stroke-focus);
			outline-offset: 0;
		}
		:global(.usingKeyboard) &:focus {
			box-shadow: 0 0 0 2px var(--stroke-focus);
		}

		&[disabled],
		&.is-disabled, {
			opacity: .5;
		}
	}

	&.isLoading .loadedIcon {
		display: flex;
	}
}

.loadedIcon {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	background: inherit;
	color: currentColor;
	align-items: center;
	justify-content: center;
	display: none;

	svg {
		will-change: transform;
		animation: circle 1s infinite linear;
	}
}
@keyframes circle {
	100% {transform: rotate(360deg)}
}

.transparent {
	color: var(--text-black);
	background: transparent;

	&.sm,
	&.md {
		//height: auto;
		padding: 2px 4px;
	}

	&.md {
		font-size: 16px;
		&.onlyIcon {
			padding-inline: 8px;
		}
	}
	&.sm {
		font-size: 13px;
		&.onlyIcon {
			padding: 6px;
		}
	}

	&:hover,
	&:active {
		background: var(--background-gray);
	}

	&:focus-visible {
		outline: 2px solid var(--stroke-focus);
		outline-offset: 0;
	}
	:global(.usingKeyboard) &:focus {
		box-shadow: 0 0 0 2px var(--stroke-focus);
	}

	&.isLoading,
	&[disabled],
	&.is-disabled {
		color: var(--text-gray);
	}

	&.isLoading {
		color: transparent !important;
		transition: none !important;
	}

	&.isLoading .loadedIcon {
		display: flex;
		color: var(--text-black);

		& ~ svg {
			opacity: 0;
		}
	}
}

.underlined {
	padding: 0;
	font-size: 13px;
	text-decoration: underline;

	&.primary {
		color: var(--text-green);

		&:focus-visible:not(.isLoading) {
			color: var(--background-green-active);
		}
		:global(.usingKeyboard) &:focus:not(.isLoading) {
			color: var(--background-green-active);
		}
	}
	&.secondary {
		color: var(--text-gray);

		&:focus-visible:not(.isLoading) {
			color: var(--text-black);
		}
		:global(.usingKeyboard) &:focus:not(.isLoading) {
			color: var(--text-black);
		}
	}

	&.isLoading,
	&[disabled],
	&.is-disabled, {
		opacity: .6;
	}
}

/* * * Safari 9+ specific css * * */
@supports (-webkit-hyphens: none) {
	.btn {
		transition:
			color .4s,
			background .4s;
	}
}
