.separator {
  width: 100%;
  height: 1px;
  background-color: var(--border-divider);
}

.buttonsPanel {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
}

.buttonsPanelItem {
  width: 100%;
}
