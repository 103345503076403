.notification {
	position: relative;
	padding: 12px 16px;
	border-radius: 8px;
	color: var(--text-black);
	font: 400 var(--fs-13) var(--font);
	text-align: left;
	display: inline-block;
}
.wrap {
	padding-top: 8px;
	opacity: 0;
	transition: opacity .6s ease;
}
.active {
	opacity: 1;
}

.success {
	border: 1px solid var(--border-green);
	background: var(--notification-bg-positive);
}

.warning {
	border: 1px solid var(--border-amber);
	background: var(--notification-bg-info);
}

.error {
	border: 1px solid var(--border-error);
	background: var(--notification-bg-negative);
}

.noticeWrap {
	z-index: 40;
	position: fixed;
	bottom: 40px;
	max-width: 500px;
	padding-left: 24px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: left .4s ease;
	max-height: calc(100vh - 80px);
	overflow: auto;

	&::-webkit-scrollbar{
		display:none;
	}

	& {
		-ms-overflow-style:none;
		scrollbar-width:none;
	}
}
