@import 'src/shared/lib/styles/config/mixins';

.searchAsignee {
  box-sizing: border-box;
  padding: 12px 12px 0;
  background-color: var(--background-white);
  border-radius: 8px;
  width: 362px;
}

.searchInput {
  background-color: var(--background-light-gray);
  border-color: var(--background-light-gray);
}

.searchInputWrapper {
  margin: 0 -12px;
  padding: 0 12px 12px;
  border-radius: 8px;
  background-color: var(--background-white);
  box-shadow: var(--shadow-initial);
  transition: box-shadow var(--trans-fast);
  clip-path: inset(0 0 -100% 0);

  &.withShadow {
    border-radius: 8px 8px 0 0;
    box-shadow: var(--shadow-header);
  }
}

.scroll {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 0 -12px 0 0;
  padding: 0 12px 12px 0;
  overflow-y: auto;
  backface-visibility: hidden;

  @include vertical-scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  :global {
    .ps__rail-y {
      margin: 6px 4px 6px 0;
      height: calc(100% - 12px) !important;
      width: 4px;
    }
    .ps__thumb-y {
      width: 4px;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  box-sizing: border-box;
}
