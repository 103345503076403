@import "src/shared/lib/styles/config/typography";
@import 'src/shared/lib/styles/config/mixins';

.searchList {
  background-color: var(--background-white);
  padding: 12px;
  width: 338px;
  max-height: calc((36px + 8px) * 10 - 8px + 24px);
  overflow-y: scroll;
  backface-visibility: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  scrollbar-width: none;
  margin: 0 -7px;
  @include vertical-scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  :global {
    .ps__rail-y {
      margin: 6px 4px 6px 0;
      height: calc(100% - 12px) !important;
      width: 4px;
    }
    .ps__thumb-y {
      width: 4px;
    }
  }
}

.notFound {
  height: auto;
}

@media (max-height: 980px) {
  .searchList {
    max-height: calc((36px + 8px) * 9 - 8px + 24px);
  }
}

@media (max-height: 936px) {
  .searchList {
    max-height: calc((36px + 8px) * 8 - 8px + 24px);
  }
}

@media (max-height: 892px) {
  .searchList {
    max-height: calc((36px + 8px) * 7 - 8px + 24px);
  }
}

@media (max-height: 848px) {
  .searchList {
    max-height: calc((36px + 8px) * 6 - 8px + 24px);
  }
}

@media (max-height: 804px) {
  .searchList {
    max-height: calc((36px + 8px) * 5 - 8px + 24px);
  }
}
