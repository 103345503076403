.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  column-gap: 8px;
}

.button {
  flex-shrink: 0;
}

.notification {
  width: 304px;
  margin-top: -32px;
}

.notificationContent {
  width: 100%;
}