.avatar {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	max-width: 100%;
	border-radius: 20px;

	&:hover .showFullNameOnHover {
		opacity: 1;
	}
}

div.avatar {
	cursor: default;

	.roundName {
		user-select: none;
		cursor: initial;
	}
}

button.avatar {
	cursor: pointer;
	outline: none;

	&:focus-visible {
		outline: 2px solid var(--stroke-focus);
	}
	:global(.usingKeyboard) &:focus {
		outline: 2px solid var(--stroke-focus);
	}
}

div.roundName {
	outline: none;
	cursor: default;
}

button.firstLine,
button.roundName {
	cursor: pointer;
	outline: none;

	&:focus-visible {
		outline: 2px solid var(--stroke-focus);
	}
	:global(.usingKeyboard) &:focus {
		box-shadow: 0 0 0 2px var(--stroke-focus);
	}
}

.roundName {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 50%;
	font-weight: 500;
	position: relative;
	color: var(--text-white);
	border: 1px solid var(--background-white);
	box-sizing: border-box;

	&:hover .fullName {
		opacity: 1;
	}

	span {
		line-height: 1;
	}

	svg {
		position: absolute;
		bottom: 1px;
		right: 1px;
		fill: var(--text-black);
		stroke: var(--text-black);
		stroke-width: 2;
	}

	&.sm {
		min-width: 28px;
		max-width: 28px;
		height: 28px;
		font-size: 10px;

		svg {
			width: 9px;
			height: 9px;
		}
	}
	&.md {
		max-width: 32px;
		min-width: 32px;
		height: 32px;
		font-size: 13px;

		svg {
			width: 11px;
			height: 11px;
		}
	}
}
.roundName:hover {
	.promptText {
		opacity: 1;
	}
}

.red {
	background: var(--avatar-red);
}
.indigo {
	background: var(--avatar-indigo);
}
.orange {
	background: var(--avatar-orange);
}
.pink {
	background: var(--avatar-pink);
}
.blue {
	background: var(--avatar-blue);
}
.amber {
	background: var(--avatar-amber);
}
.purple {
	background: var(--avatar-purple);
}
.teal {
	background: var(--avatar-teal);
}
.gray {
	background: var(--avatar-gray);
}
.deep-purple {
	background: var(--avatar-deep-purple);
}
.green {
	background: var(--avatar-green);
}
.blue-gray {
	background: var(--avatar-blue-gray);
}

.withNameBG {
	.textWrap {
		max-width: calc(100% - 8px - 16px);
	}

	.textWrap::after {
		background: var(--background-gray);
		content: '';
		top: 0;
		bottom: 0;
		left: 16px;
		position: absolute;
		right: 0;
		z-index: -1;
		border-radius: 0 30px 30px 0;
	}

	&.sm .textWrap{
		&::after {
			left: 14px;
		}
	}
	&.md .textWrap {
		&::after {
			left: 16px;
		}
	}
	p {
		padding-right: 14px;
	}
	.firstLine .name {
		max-width: 100%;
		overflow: hidden;
		display: block;
		text-overflow: ellipsis;
	}
}

.withNameBG,
.regular {
	.firstLine .name {
		max-width: 100%;
		overflow: hidden;
		display: block;
		text-overflow: ellipsis;
	}
}

.regularForComment,
.regular {
	.firstLine .name {
		font-weight: 700;
	}
}

.regular {
	cursor: pointer;
}

.regularForComment {
	align-items: initial;

	.firstLine .name {
		margin-right: .5em;
	}
}

.withNameBG,
.regular,
.regularForComment {
	.promptText {
		display: none;
	}
}

.mail,
.firstLine .name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.time {
	color: var(--text-gray);
}

.mail {
	color: var(--text-gray);
}

.textWrap {
	padding: 0 0 0 8px;
	text-align: left;
	max-width: calc(100% - 8px);

	.firstLine, p {
		font-size: 13px;
		max-width: 100%;
		line-height: 1.2;
	}
}

.sm .textWrap {
	max-width: calc(100% - 8px - 28px);
}

.md .textWrap {
	max-width: calc(100% - 8px - 32px);
}

.promptText,
.showFullNameOnHover {
  pointer-events: none;
	composes: promptText from global;
}

.promptText.inverse,
.showFullNameOnHover.inverse {
	left: auto;
	right: 0;
}
