@import 'src/shared/lib/styles/config/mixins';

.box {
  position: relative;
  width: 362px;
  border-radius: inherit;
}

/*
 * Поиск
 */
.search {
  padding: 12px;
}

.createTagContainer {
  padding: 0 12px 12px 8px;
}

/*
 * Список
 */
.tagsList {
  margin: -12px 0 0;
}

.tagsListScroller {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 12px;
  padding: 12px;
  max-height: 172px;
  box-sizing: border-box;
  overflow-y: auto;
  backface-visibility: hidden;

  @include vertical-scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  :global {
    .ps__rail-y {
      margin: 12px 4px;
      height: calc(100% - 24px) !important;
      width: 4px;
    }
    .ps__thumb-y {
      width: 4px;
    }
  }
}

.tagsListMessage {
  padding: 12px;
  color: var(--text-gray);
}

/*
 * Тулзы
 */
.tools {
  margin-top: -12px;
  padding: 12px;
}
