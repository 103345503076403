.tooltip {
	position: absolute;
	z-index: 20;
	padding: 2px 4px;
	white-space: nowrap;
	border-radius: 2px;
	user-select: none;
	pointer-events: none;
	opacity: 0;
	transition:
		opacity var(--trans-fast);
}

.dark {
	color: var(--text-white);
	background: var(--background-tooltip-black);
	border: 1px solid var(--background-black);
}

.light {
	color: var(--text-black);
	background: var(--background-white);
	border: 1px solid var(--stroke-focus);
}

.isShown {
	opacity: 1;
}

*:focus-visible + .tooltip {
	opacity: 1;
}
:global(.usingKeyboard) *:focus + .tooltip {
	opacity: 1;
}

*:hover + .tooltip {
	opacity: 1;
	transition-delay: 1s;
}

/*
 * Для глобальных тултипов приложения
 */
.showWithAnimation {
	opacity: 0;
	transition:
		opacity var(--trans-fast);

	&.fadeIn {
		animation: fadeIn var(--trans-fast) forwards;
		animation-delay: 1s;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		pointer-events: none;
	}
	to {
		opacity: 1;
		pointer-events: auto;
	}
}
