.wrapper {
  position: relative;
  display: inline-block;
}

.target_button {
  display: inline-block;
}

.dropdown {
  position: absolute;
  display: inline-flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity .2s linear, visibility .2s;
  visibility: hidden;
  z-index: 50;
  box-shadow: var(--shadow-dropdown);
  border-radius: 8px;
}

.open {
  opacity: 1;
  visibility: visible;
  transition: opacity .2s linear, visibility .2s;
}