:root {
  /* *
   * Viewports units
   */
  --vh: 100vh;
  --vw: 100vw;

  /*
   * Sizes
   */
  --min-width: 768px;

  /* *
   * Colors by design :: text
   */
  --text-black: #33353F;
  --text-gray: #7E7E89;
  --text-white: #fff;
  --text-green: #489654;
  --text-error: #FC5A5A;

  /* *
   * Colors by design :: background
   */
  --background-black: #262626;
  --background-white: #fff;

  --background-gray: #E5E7E9;
  --background-gray-hover: #DCDEE0;

  --background-light-gray: #F2F3F4;
  --background-active-menu: #E9F5EB;

  --background-green: #489654;
  --background-green-hover: #398745;
  --background-green-active: #2F7D3B;

  --background-red: #DB5C5C;
  --background-red-hover: #D15252;
  --background-red-active: #C44545;

  /* *
   * Colors by design :: border
   */
  --border-divider: #E0E0EB;

  --border-green: #489654;
  --border-light-green: #A3CBA6;

  --border-gray: #DFE1E3;
  --border-light-gray: #EBECED;

  --border-error: #FC5A5A;
  --border-amber: #FDC107;

  /* *
   * Colors by design :: notification
   */
  --notification-bg-positive: #E9F5EB;
  --notification-bg-negative: #F9DDDD;
  --notification-bg-info: #F9F4DD;

  /* *
   * Colors custom
   */
  --stroke-focus: #ccc;

  --background-tooltip-black: rgba(51, 53, 63, 0.95);

  --background-green-datepicker-start: #A5CEAA;
  --background-green-datepicker-range: var(--background-active-menu);

  /* *
   * Colors avatars
   */
  --avatar-red: #F34334;
  --avatar-indigo: #3F51B5;
  --avatar-orange: #FE9904;
  --avatar-pink: #EA1E63;
  --avatar-blue: #05A9F5;
  --avatar-amber: #FDC107;
  --avatar-purple: #9C28B1;
  --avatar-teal: #049587;
  --avatar-gray: #9E9E9E;
  --avatar-deep-purple: #673BB7;
  --avatar-green: #4CB050;
  --avatar-blue-gray: #607D8B;

  /* *
   * Colors tags
   */
  --tag-red: #F1CDCD;
  --tag-light-blue: #CFE6F7;
  --tag-grey: #DDDDE8;
  --tag-pink: #F6D6EB;
  --tag-green: #CCEDD2;
  --tag-purple: #E3D0EF;
  --tag-orange: #F7DDBE;
  --tag-blue: #C4D0FB;
  --tag-yellow: #EFF0AC;

  /* *
   * Colors shadow
   */
  --shadow-curtain: rgba(0,0,0,.3);
  --shadow-initial:
    0 0 0 rgba(0, 0, 0, 0);
  --shadow-dropdown:
    0px 429px 500px rgba(0, 13, 57, 0.0018182),
    0px 190.268px 244.125px rgba(0, 13, 57, 0.00505165),
    0px 98.0524px 160.197px rgba(0, 13, 57, 0.00789993),
    0px 48.5968px 105.649px rgba(0, 13, 57, 0.013994),
    0px 20.7088px 64.163px rgba(0, 13, 57, 0.0472),
    0px 4.94px 29.8776px rgba(0, 13, 57, 0.12);
  --shadow-card:
    0 6px 16px 0 #000D1A0A;
  --shadow-header:
    0 4px 28px rgba(0, 13, 57, 0.1);
  --shadow-focus-green:
    0 0 0 2px rgba(72, 150, 84, .35);

  /* *
   * Fonts
   */
  --font: 'Inter', sans-serif;

  --lh: 1.23;
  --llp: 14px;

  --fs-14: 14px/16px;
  --fs-13: 13px/16px;

  /* *
   * Headers typography
   */
  --h1: 24px/1.291666666666667;
  --h2: 18px/1.3;
  --h3: 16px/1.3;
  --h4: 14px/1.3;
  --h5: 13px/1.3;

  /* *
   * Transition & ease
   */
  --ease: cubic-bezier(.215, .61, .355, 1);

  --trans-fast: .4s var(--ease);
  --trans-medium: .6s var(--ease);
  --trans-slow: .8s var(--ease);
  --trans: var(--trans-fast);
}
