.parentTaskTitle {
	padding: 6px 12px;
	max-width: 100%;

	span {
		display: block;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
