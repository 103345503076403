.event {
  display: flex;
  column-gap: 8px;
}
.main {
  display: flex;
  flex-direction: column;
  row-gap: 0;
}
.name {
  font: 700 var(--fs-13) var(--font);
}
.text {
  font: 400 var(--fs-13) var(--font);
}