.wrap {
	position: relative;
	width: 304px;
}

.group {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	row-gap: 12px;
	padding: 12px 8px;

	&:empty {
		display: none;
	}
}

.groupStroked {
	row-gap: 10px;
	margin-top: -2px;
	padding-top: 10px;
	border-top: 1px solid var(--border-divider);
}

.actionButton {
	padding: 2px 4px;
	height: 20px;
	width: 100%;
	justify-content: flex-start;
	font-weight: 400;
}

.settingsChoice {
	margin-left: 4px;
}
