/*
 * Headers
 */
.h1, h1 {
  font: 700 var(--h1) var(--font);
}
.h2, h2 {
  font: 700 var(--h2) var(--font);
}
.h3, h3 {
  font: 700 var(--h3) var(--font);
}
.h4, h4 {
  font: 700 var(--h4) var(--font);
}
.h5, h5,
.h6, h6 {
  font: 700 var(--h5) var(--font);
}

/*
 * Typography mixins
 */
@mixin header-24-bold {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

@mixin header-16-semi-bold {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

@mixin text-14-bold {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
}

@mixin text-14-medium {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

@mixin text-13-bold {
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
}

@mixin text-13-medium {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

@mixin text-13-regular {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

@mixin text-13-medium-lined {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: underline;
}

@mixin text-11-regular {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}
