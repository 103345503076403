@import "src/shared/lib/styles/config/mixins";

.control {
	position: relative;
	width: 24px;
	height: 24px;
	box-sizing: border-box;
	cursor: pointer;

	input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}

.checkbox {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	background: currentColor;
	border-radius: 50%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 50%;
		width: 100%;
		height: 100%;
		border: 1px solid var(--background-green);
		box-sizing: border-box;
		opacity: 0;
		transition: opacity .4s ease;
	}

	svg {
		width: 16px;
		height: 16px;
		stroke: var(--text-black);
		transition: opacity .4s ease;
		opacity: 0;
	}

	input:checked ~ & {
		&::before,
		svg {
			opacity: 1;
		}
	}
}

.frame {
	@include absoluteFitIndent(-3px);
	background: currentColor;
	border-radius: 50%;
	opacity: 0;
	pointer-events: none;
	transition: opacity var(--trans-fast);

	input:hover ~ & {
		opacity: .4;
	}
	input:focus-visible ~ & {
		@include absoluteFitIndent(-2px);
		opacity: .5;
		background: var(--background-green) !important;
	}
	:global(.usingKeyboard) input:focus ~ & {
		@include absoluteFitIndent(-2px);
		opacity: .5;
		background: var(--background-green) !important;
	}
}
