.headerShadowWrapper {
  position: absolute;
  left: -16px;
  top: 0;
  right: -16px;
  bottom: 0;
  pointer-events: none;
}

.headerShadow {
  position: sticky;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  height: 28px;
  box-shadow: var(--shadow-header);
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition:
    opacity var(--trans-medium);

  &.isShown {
    opacity: 1;
  }
}