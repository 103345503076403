@import 'src/shared/lib/styles/config/mixins';

.actionsWrapper,
.participantsWrapper,
.toolsWrapper,
.viewsWrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.actionsWideButton {
  min-width: 150px;
}

.dropBox {
  min-width: 304px;
  min-height: 38px;
  background: var(--background-white);
  border-radius: 8px;
  box-sizing: border-box;
}

/*
 * Section participants
 */
.participants {
  display: flex;
  column-gap: 8px;
}

/*
 * Board view
 */
.boardViewSelect {
  min-width: 163px;

  & > div {
    display: block;
    width: 100%;
  }

  button {
    width: 100%;
  }
}

/*
 * Board view save
 */
.boardViewSave {
  padding-left: 11px;
  padding-right: 11px;
}

/*
 * Table settings
 */
.tableSettings {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 12px;
  margin-top: 8px;
  padding: 12px;
}

.tableSettingsValue {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.tableSettingsButton {
  width: 100%;
}

/*
 * Tags select
 */
.selectTags {
  width: 156px;
  padding: 8px 12px;
  justify-content: space-between;
}

.duplicateTags {
  display: flex;
  justify-content: space-between;
}

.duplicateTagsText {
  max-width: 204px;
  white-space: pre-wrap;
}

.tags {
  box-sizing: border-box;
  width: 260px;
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.tagsList {
  margin: 0 -12px -12px;
  border-radius: 8px;
}

.tagsListScroller {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 12px 12px;
  max-height: 320px;
  box-sizing: border-box;
  overflow-y: auto;
  backface-visibility: hidden;

  @include vertical-scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  :global {
    .ps__rail-y {
      margin: 0 4px 12px;
      height: calc(100% - 12px) !important;
      width: 4px;
    }
    .ps__thumb-y {
      width: 4px;
    }
  }
}

.tagsListItem {
  margin-right: -8px;
  color: var(--text-black);

  svg {
    stroke: currentColor;
  }
}
