@import "src/shared/lib/styles/config/typography";

.header-24-bold {
	@include header-24-bold;
}

.header-16-semi-bold {
	@include header-16-semi-bold;
}

.text-14-bold {
	@include text-14-bold;
}

.text-14-medium {
	@include text-14-medium;
}

.text-13-bold {
	@include text-13-bold;
}

.text-13-medium {
	@include text-13-medium;
}

.text-13-regular {
	@include text-13-regular;
}

.text-13-medium-lined {
	@include text-13-medium-lined;
}

.text-11-regular {
	@include text-11-regular;
}
