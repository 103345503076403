.asideNav {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 208px;
  background: var(--background-white);
  padding: 24px;
  border-radius: 0 10px 10px 0;
  transition: all .4s ease;
  box-sizing: border-box;

  [datatype="btntext"] {
    white-space: nowrap;
    transition: opacity .4s ease;
  }
}

.changeSizeBtn {
  width: 100%;
  padding: 8px;

  svg {
    position: relative;
    left: 0;
    transition:
      left .4s ease,
      transform .4s ease;
  }
}

.asideMinimized {
  width: 80px;

  .changeSizeBtn svg {
    left: 34px;
    transform: rotate(180deg);
  }

  [datatype="btntext"] {
    opacity: 0;
  }
}

.asideMenu {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 56px;
}

.asideMenuItem {
  position: relative;
}

.asideMenuButton {
  width: 100%;
  padding: 6px;
  column-gap: 8px;
  justify-content: flex-start;
  color: #7E7E89;

  svg {
    flex-shrink: 0;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
  }

  &:focus-visible {
    outline: 2px solid var(--stroke-focus);
  }
  :global(.usingKeyboard) &:focus {
    box-shadow: 0 0 0 2px var(--stroke-focus);
  }

  &.isRoot {
    pointer-events: none;
  }

  &.isActive {
    color: var(--text-green) !important;
    background: var(--background-active-menu);

    &:focus-visible {
      outline: 2px solid var(--background-green);
    }
    :global(.usingKeyboard) &:focus {
      box-shadow: 0 0 0 2px var(--background-green);
    }
  }
  &:hover {
    color: var(--text-black);
  }
}

.asideMenuMark {
  position: absolute;
  left: -15px;
  top: -1px;
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  background: var(--background-green);
  box-shadow: 0 0 0 2px var(--background-light-gray);
  border-radius: 50%;
}
