.skeleton {
  display: block;
  left: 0;
  top: 0;
  height: .9em;
  background-color: var(--background-gray);
  background-image: linear-gradient(90deg, var(--background-gray), var(--background-light-gray), var(--background-gray));
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 3px;
  animation: skeleton 1200ms ease infinite;
  pointer-events: none;

  &.light {
    background-color: var(--background-light-gray);
    background-image: linear-gradient(90deg, var(--background-light-gray), #f2f2f2, var(--background-light-gray));
  }
}

@keyframes skeleton {
  from { background-position: -200px 0; }
  to { background-position: calc(200px + 100%) 0; }
}
