/*
 * Хэдер
 */
.head {
	position: relative;
	padding: 17px 12px 15px;
	text-align: center;
	background: var(--background-white);
	border-bottom: 1px solid var(--border-divider);
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;

	&.sm {
		padding: 13px 12px 11px;
	}
}

.headBackBtn {
	position: absolute;
	left: 12px;
	top: 13px;
	width: 24px;
	height: 24px;

	svg {
		fill: currentColor;
		stroke: none;
	}
}

.head.sm .headBackBtn {
	top: 9px;
}

.headCloseBtn {
	position: absolute;
	right: 12px;
	top: 13px;
	width: 24px;
	height: 24px;
	padding: 0 !important;

	svg {
		min-width: 16px;
		height: 16px;
		stroke-width: 1px;
	}
}

.head.sm .headCloseBtn {
	top: 9px;
}

/*
 * Тело
 */
.body {
	position: relative;
	padding: 16px 12px;
	background: var(--background-white);
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}

.text {
	margin-bottom: 16px;
	word-break: break-word;
	text-align: center;

	&.left {
		text-align: left;
	}
	&.center {
		text-align: center;
	}
	&.right {
		text-align: right;
	}
}

.buttons {
	display: flex;
	flex-flow: row nowrap;
	column-gap: 8px;
}

.actionButton {
	width: 100%;
}
