/* -------------------- GS :: flex -------------------- */
.flex { display: flex; flex-flow: row wrap; align-items: stretch; box-sizing: border-box; }
.flex .flex-col { min-height: 1px; box-sizing: border-box; }

.flex-center { justify-content: center; }
.flex-right { justify-content: flex-end; }
.flex-justify { justify-content: space-between; }

.flex-vertical-top { align-items: flex-start; }
.flex-vertical-center { align-items: center; }
.flex-vertical-bottom { align-items: flex-end; }


/* -------------------- GS :: utils -------------------- */
.hidden { display: none !important; }

.visible-print,
.visible-print-inline { display: none; }

@media print {
  .hidden-print { display: none; }

  .visible-print { display: block; }
  .visible-print-inline { display: inline; }
}


/* -------------------- GS :: gutters -------------------- */
.flex-gaps { margin-left: -$gap; margin-right: -$gap; }

.flex-gaps .flex-col { padding-left: $gap; padding-right: $gap; width: 100%; box-sizing: border-box; }
.flex-gaps .flex-element { margin-left: $gap; margin-right: $gap; width: calc(100% - #{$gap * 2}); }


/* -------------------- Grid for buttons in storybook -------------------- */
.flexGrid{
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  align-items: center;

  div {
    display: block;

    & + & {
      margin-top: 16px;
    }
  }

  h3 {
    opacity: .6;
    color: var(--avatar-blue);
  }

  & + & {
    margin-top: 16px;
  }
}
.flexDivider {
  margin-top: 40px;
}
