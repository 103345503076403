@import "src/shared/lib/styles/config/typography";

.roles {
  box-sizing: border-box;
  width: 247px;
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 12px;

  .button {
    @include text-13-regular;
    margin-top: 8px;
  }
}

.role:not(:first-child) {
  margin-top: 8px;
}

.separator {
  margin: 0 -12px;
  width: calc(100% + 24px);
  margin-top: 8px;
}
