.taskGroup {
  min-width: calc(100% + 24px);
  display: flex;
  column-gap: 16px;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 20%;
  min-width: 250px;
  padding-top: 3px;

  &:last-child {
    padding-right: 24px;
  }
}