.form {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	column-gap: 8px;
	width: 100%;
}

.formElement {
	width: 100%;
	min-width: 0;

	&:global(.has-error) ~ * {
		margin-bottom: 24px;
	}
}

.commentArea {
	border-radius: 6px;
	background: var(--background-light-gray);
	border: 1px solid transparent;
	transition: border var(--trans-fast);

	&.focus {
		border: 1px solid var(--background-green);
	}

	:global(.has-error) & {
		border-color: var(--border-error);
	}
}

.commentText {
	textarea {
		border: none !important;
	}
	label::after {
		border: none !important;
	}
}

.commentFiles {
	display: flex;
	flex-flow: row wrap;
	gap: 8px;
	padding: 0 8px 8px;
}

.attachTools {
	margin-left: 0;
}

.submitBtn {
	svg {
		margin-top: 1px;
	}
}
