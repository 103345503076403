@import 'src/shared/lib/styles/config/mixins';

.taskTable {
  display: table;
  position: relative;
  min-width: calc(100% + 16px);
  min-height: calc(var(--vh) - var(--hdr-offset) - 48px);
  border-right: 16px solid transparent;
  box-sizing: border-box;
  padding-top: 2px;
}

/*
 * Подложка под левой колонкой
 */
.taskTableSideSubstrate {
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  bottom: 0;
  width: 182px;
  opacity: 0;
  background: var(--background-light-gray);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition:
    opacity var(--trans-fast),
    left var(--trans-medium),
    box-shadow var(--trans-medium);

  &.shadow {
    opacity: 1;
    box-shadow: var(--shadow-header);
  }
}

/*
 * Шапка таблицы
 */
.taskTableHead {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 16px;
  position: sticky;
  z-index: 10;
  top: 0;
  min-width: fit-content;
  padding: 0 24px 12px 202px;
  margin:  0 -24px 0 -36px;
  background: var(--background-light-gray);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow var(--trans-medium);

  &.shadow {
    box-shadow: var(--shadow-header);
  }
}

.taskTableHeadCell {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
  width: 20%;
  min-width: 250px;
  transition:
    opacity var(--trans-fast),
    width var(--trans-medium),
    min-width var(--trans-medium);

  &.collapsed {
    width: 42px;
    min-width: 42px;
  }
}

.taskTableHeadCellCount {
  flex-shrink: 0;
  color: var(--text-gray);
}

/*
 * Строка таблицы
 */
.taskTableRow {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: fit-content;

  & ~ & {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px dashed var(--border-divider);
  }
}

.taskTableRowHeader {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  position: sticky;
  z-index: 8;
  left: 0;
  width: 150px;
  margin-right: 16px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow var(--trans-medium);

  .taskTableHead + .taskTableRow & {
    padding-top: 2px;
  }
}

.taskTableRowHeaderStatus {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
}

.taskTableRowHeaderCount {
  flex-shrink: 0;
  color: var(--text-gray);
}

.taskTableTag {
  width: 100%;
  max-width: 150px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  transition: width var(--trans-medium);

  .taskTableHeadCell & {
    max-width: 100%;
  }

  & > span {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  svg {
    position: relative;
    transform: rotate(180deg);
    transition: transform var(--trans-medium);
  }

  &.expanded svg {
    transform: rotate(0deg);
  }

  .tagText {
    display: flex !important;
    justify-content: flex-start;
  }

  .tagName {
    flex-shrink: 0;
    min-width: fit-content;
    transition:
      opacity var(--trans-medium),
      transform var(--trans-medium);
  }

  .tagCounter {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition:
      opacity var(--trans-medium),
      transform var(--trans-medium);
  }

  &.collapsed {
    width: 42px;

    svg {
      position: absolute;
      top: 0;
      right: -4px;
      margin: 2px 0;
      transform: rotate(0deg);
    }

    .tagName {
      opacity: 0;
    }

    .tagCounter {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.taskTableRowSpoiler {
  width: 100%;
}

.taskTableRowGrid {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 16px;
  padding-top: 36px;
  width: 100%;
}

.taskTableRowGridCompact {
  padding-top: 28px;
  min-width: 0;
}

/*
 * Колонка таблицы
 */
.taskTableColumn {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 8px;
  width: 20%;
  min-width: 250px;
  transition:
    opacity var(--trans-fast),
    width var(--trans-medium),
    min-width var(--trans-medium);

  &.collapsed {
    width: 42px;
    min-width: 42px;
    opacity: 0;
    pointer-events: none;
  }
}

.taskTableColumnCell {
  position: relative;
  min-width: 250px;
}

