.login {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
}

.button {
  width: 180px;
}