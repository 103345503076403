@import "src/shared/lib/styles/config/typography";

.listItem {
  width: 100%;
  max-width: 314px;
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.roleName {
  color: var(--text-gray);
}

.button {
  flex-shrink: 0;
}
