.moreBtn {
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 6px;
  min-width: 32px;
  background: var(--background-gray);
  border-radius: 20px;
  box-sizing: border-box;

  &:focus-visible {
    outline: 2px solid var(--stroke-focus);
  }
  :global(.usingKeyboard) &:focus {
    outline: 2px solid var(--stroke-focus);
  }
}
