/* *
 * Доска
 */
.taskBoard {
  display: flex;
  position: relative;
  margin-left: -8px;
  min-width: calc(100% + 32px);
  min-height: calc(var(--vh) - var(--hdr-offset) - 48px);
  border-right: 16px solid transparent;
  box-sizing: border-box;

  @media (max-width: 1577px) {
    min-width: 1346px;
  }
}

/* *
 * Тело колонки
 */
.column {
  flex: 0 0 auto;
  width: 20%;
  min-width: 266px;
}

.droppableColumnWrap {
  flex-wrap: wrap;
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  height: calc(100% - 20px);

  & > div {
    padding: 8px;
  }
}

.droppableColumn {
  width: 100%;
  min-height: 500px;
  border-radius: 8px;
  transition: background-color .4s ease;
  box-sizing: border-box;

  &.isDraggingOver {
    background: var(--background-gray);
  }
}

/* *
 * Элемент в колонке
 */
.item {
  margin-bottom: 16px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  user-select: none;
}
