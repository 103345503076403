.filesWrap {
	display: flex;
	flex-flow: row nowrap;
	max-width: 100%;
}

.toolsSlot {
	display: flex;
	flex-flow: row nowrap;
	flex-shrink: 0;
}