@import "src/shared/lib/styles/config/typography";

.calendarAriaWrap {
  width: 100%;
}

.calendar {
  width: 100%;

  &:global(.rmdp-wrapper) {
    position: relative;
    width: 100% !important;
    box-shadow: none !important;
    background: inherit !important;
    border-radius: inherit !important;
  }

  :global {
    .rmdp-calendar {
      padding: 0;
      max-width: 238px;
      width: 100%;
    }

    .rmdp-header {
      height: 24px;
      margin-top: 0;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
    }

    .rmdp-header-values {
      color: var(--text-black);
      text-transform: capitalize;
    }

    .rmdp-day-picker {
      padding: 0;

      & > div {
        width: 100%;
      }
    }

    .rmdp-week {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      align-items: center;
      justify-items: center;
      height: 28px;
    }

    .rmdp-day,
    .rmdp-week-day {
      width: 24px;
      height: 24px;
    }

    .rmdp-week-day {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--text-gray);
    }

    .rmdp-day {
      background: none;
      box-shadow: none;
      border-radius: 5px !important;

      span {
        @include text-13-medium;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        padding-top: 2px;
        border-radius: 5px !important;
        transition:
          color var(--trans-fast),
          background var(--trans-fast);
      }

      &.rmdp-today {
        span {
          color: var(--text-black);
          background: var(--background-gray);
        }
      }

      &.rmdp-day {
        &:not(.rmdp-disabled):not(.rmdp-day-hidden) {
          color: var(--text-black);

          span:hover {
            color: var(--text-black);
            background: var(--background-gray);
          }

          span:focus {
            outline: none;
          }
          span:focus-visible {
            outline: none;
          }
          span:focus-visible:before {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            border-radius: 5px !important;
            box-shadow: 0 0 0 2px var(--stroke-focus);
            content: '';
          }
        }

        span.start {
          z-index: 1;
          color: var(--text-black);
          background: var(--background-green-datepicker-start);
        }

        &:not(.rmdp-disabled) {
          span.start:hover {
            color: var(--text-white);
            background-color: var(--background-green);
          }
        }

        span.end {
          z-index: 1;
          color: var(--text-white);
          background-color: var(--background-green);
        }

        &:not(.rmdp-disabled) {
          span.end:hover {
            color: var(--text-white);
            background-color: var(--background-green-hover);
          }
        }

        span.single {
          color: var(--text-white) !important;
          background-color: var(--background-green) !important;
        }

        span.inter {
          z-index: auto;
          left: -15px;
          right: -15px;
          padding-left: 15px;
          padding-right: 15px;
          color: var(--text-black);
          background-color: var(--background-green-datepicker-range);
          border-radius: 0 !important;
          transition: none;

          &:focus-visible:before {
            left: 15px !important;
          }
        }

        span.nearby.start {
          z-index: auto;

          &::after {
            content: '';
            position: absolute;
            width: 18px;
            height: 100%;
            right: -14px;
            top: 0;
            z-index: -1;
            background-color: var(--background-green-datepicker-range);
          }
        }

        &:not(.rmdp-disabled) {
          span.inter:hover {
            z-index: 1;
            left: 0;
            right: 0;
            padding-left: 0;
            padding-right: 0;
            color: var(--text-white);
            background-color: var(--background-green);
            border-radius: 5px !important;
          }
        }

        span.inter.first,
        &:first-of-type span.inter {
          left: 0;
          padding-left: 0;
          border-radius: 5px 0 0 5px !important;

          &:focus-visible:before {
            left: 0 !important;
          }
        }

        span.inter.last,
        &:last-of-type span.inter {
          right: 0;
          padding-right: 0;
          border-radius: 0 5px 5px 0 !important;
        }
      }

      &.rmdp-selected {
        span {
          color: var(--text-white) !important;
          background-color: var(--background-green) !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
