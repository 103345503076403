.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 8px;
  padding-top: 2px;
}

.info {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 8px;
  min-width: 0;
  padding-top: 4px;
}

.authorName {
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font: 700 var(--h5) var(--font);
}