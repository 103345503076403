/* -------------------- Inter -------------------- */
@font-face {
	font-family: 'Inter';
	src:
		local('Inter Thin'),
		local('Inter-Thin'),
		url('../../fonts/inter/Inter-Thin.woff2') format('woff2'),
		url('../../fonts/inter/Inter-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter ExtraLight'),
		local('Inter-ExtraLight'),
		url('../../fonts/inter/Inter-ExtraLight.woff2') format('woff2'),
		url('../../fonts/inter/Inter-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter Light'),
		local('Inter-Light'),
		url('../../fonts/inter/Inter-Light.woff2') format('woff2'),
		url('../../fonts/inter/Inter-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter Regular'),
		local('Inter-Regular'),
		url('../../fonts/inter/Inter-Regular.woff2') format('woff2'),
		url('../../fonts/inter/Inter-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter Medium'),
		local('Inter-Medium'),
		url('../../fonts/inter/Inter-Medium.woff2') format('woff2'),
		url('../../fonts/inter/Inter-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter SemiBold'),
		local('Inter-SemiBold'),
		url('../../fonts/inter/Inter-SemiBold.woff2') format('woff2'),
		url('../../fonts/inter/Inter-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter Bold'),
		local('Inter-Bold'),
		url('../../fonts/inter/Inter-Bold.woff2') format('woff2'),
		url('../../fonts/inter/Inter-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter ExtraBold'),
		local('Inter-ExtraBold'),
		url('../../fonts/inter/Inter-ExtraBold.woff2') format('woff2'),
		url('../../fonts/inter/Inter-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src:
		local('Inter Black'),
		local('Inter-Black'),
		url('../../fonts/inter/Inter-Black.woff2') format('woff2'),
		url('../../fonts/inter/Inter-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
