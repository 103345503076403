.titleWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: sticky;
  top: 0;
  left: 0;
  gap: 8px;
  width: 100%;
  margin-bottom: -8px;
  padding: 0 12px 12px 20px;
  font: 500 var(--fs-14) var(--font);
  background: var(--background-light-gray);
  box-sizing: border-box;

  &.isAbove {
    z-index: 5;
  }
}

.title {
  font-weight: 700;
}

.titleAmount {
  color: var(--text-gray);
}
