.taskEvents {
	padding-top: 24px;
	margin-bottom: 10px;
}

.head {
	margin-bottom: 16px;
}

/*
 * Feed
 */
.dateTile {
	margin: 16px 0;
	text-align: center;
	color: var(--text-gray);
}

.itemsList {
	display: flex;
	flex-flow: column nowrap;
	row-gap: 12px;
}

/*
 * Comment edit mode
 */
.commentAttachButton {
	padding: 0 !important;
	width: 24px;

	svg {
		width: 16px;
		max-width: 16px;
		height: 16px;
	}
}

/*
 * Load more
 */
.loadMoreButton {
	display: flex;
	justify-content: center;
	margin-top: 16px;

	button {
		font-weight: 400;
	}
}

/*
 * Empty
 */
.emptyBanner {
	margin: 16px auto;
	color: var(--text-gray);
}

/*
 * Event mock
 */
.mock {
	display: flex;
	flex-flow: row nowrap;
	column-gap: 8px;

	& ~ & {
		margin-top: 16px;
	}

	.mockAvatar {
		border-radius: 50%;
	}
	.mockContent {
		display: flex;
		flex-flow: column nowrap;
		row-gap: 4px;
		padding-top: 2px;
	}
}
