.tags {
	display: flex;
	flex-flow: column nowrap;
}

.tagsWrap {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	align-items: flex-start;
	column-gap: 8px;
	max-width: 100%;
}

.tagsArray {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
	max-width: 100%;
	min-width: 0;
	justify-content: flex-end;

	button {
		max-width: 100%;
		box-sizing: border-box;
	}
}

.tagsDrop {
	 min-width: 304px;
	 min-height: 38px;
	 background: var(--background-white);
	 border-radius: 8px;
	 box-sizing: border-box;
 }
