.transferTask {
  width: 362px;
  border-radius: 8px;
}

.title {
  margin-bottom: 8px;
}

.avatar {
  z-index: 1;
}

.searchInput {
  width: 338px;
}

.list {
  box-sizing: border-box;
  width: 338px;
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: calc((32px + 8px) * 10 - 8px + 24px - 4px);
  overflow-y: scroll;
  backface-visibility: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}
