@import "src/shared/lib/styles/config/typography";

.label {
	display: flex;
	align-items: center;
	column-gap: 4px;
	color: var(--text-gray);
	opacity: 0;
	pointer-events: none;
	transition:
		opacity var(--trans-medium);

	svg {
		width: 12px;
		height: 12px;
		fill: currentColor;
	}

	&.accepted {
		color: var(--text-green);
	}

	&.shown {
		opacity: 1;
	}
}
