@import "src/shared/lib/styles/config/typography";

.participant {
  box-sizing: border-box;
  width: 260px;
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 12px;
}

.separator {
  width: calc(100% + 24px);
  margin: 12px -12px 0 -12px;
}

.role {
  margin-top: 12px;
}

.button {
  margin-top: 12px;
}

.disabled {
  pointer-events: none;
}
