.assigneeDropdown {
  box-sizing: border-box;
  min-width: 260px;
  max-width: 260px;
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 12px;
}

.separator {
  width: calc(100% + 24px);
  margin: 12px -12px 0 -12px;
}

.button {
  margin-top: 12px;
}

.notification {
  margin: -12px;
  width: 304px;
  border-radius: inherit;
}
