@import "src/shared/lib/styles/config/mixins";

.approvalMessage {
	min-width: 362px;
	max-width: 362px;
	background: var(--background-light-gray);
	border-radius: inherit;
}

.fieldset {
	& ~ & {
		margin-top: 16px;
	}

	.legend {
		margin-bottom: 8px;
	}
}

.approvalMessageRead {
	min-width: 304px;
	max-width: 304px;
	background: var(--background-light-gray);
	border-radius: inherit;
}

.approvalMessageReadText {
	position: relative;
	padding: 16px 12px;
	background: var(--background-white);
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
	word-break: break-word;
	text-align: left;
}
