@import 'src/shared/lib/styles/config/mixins';

.convertToTask {
  width: 400px;
  border-radius: 8px;
}

.body {
  margin-bottom: 32px;
}

.fieldset {
  display: flex;
  column-gap: 8px;
  max-width: 100%;
  min-width: 0;

  & ~ & {
    margin-top: 16px;
  }

  .legend {
    margin-bottom: 8px;
  }
}

.fieldsetColumn {
  width: 50%;
  height: 52px;

  & ~ & {
    width: auto;
    min-width: 50%;
    flex-shrink: 0;
  }
}

.boardsSelect {
  & > div,
  & > div > button {
    width: 100%;
  }
}

.boardsSelectEmpty {
  padding: 8px 12px;
  margin: 0;
  width: 100%;
  max-width: 100%;
  color: var(--text-gray);
  box-sizing: border-box;
}
