.modal {
	margin: 24px 0;
	min-height: calc(100vh - 48px);
	background: var(--background-white);
}

.body {
	display: flex;
	flex-flow: column nowrap;
	row-gap: 12px;
	padding: 16px;
}

.emptyBanner {
	margin-top: 24px;
	margin-bottom: 24px;
}

.buttonsBlock {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	column-gap: 8px;

	button {
		font-weight: inherit;
	}
}

.clearAll {
	display: inline-block;
	position: relative;
}

.clearAllConfirm {
	width: 304px;
	border-radius: 8px;
}
