.header {
  position: sticky;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px 24px 24px 36px;
  background: var(--background-light-gray);
  white-space: nowrap;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 48px;
  row-gap: 16px;
  min-height: 32px;
  box-sizing: border-box;

  & ~ & {
    margin-top: 16px;
  }
}

.left {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
  min-width: 0;
}

.right {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}

.backBtn {
  flex-shrink: 0;
  width: 32px;
  padding: 0 !important;

  svg {
    max-width: 20px;
    min-width: 20px;
    height: 20px;
  }
}

.titleWrap {
  min-width: 0;
}

.title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleTooltip {
  position: absolute;
  left: 0;
  top: 100%;
  max-width: 50vw;
  white-space: normal;
  word-wrap: break-word;
}

.titleWrap:hover ~ .titleTooltip {
  opacity: 1;
  transition-delay: 1s;
}
