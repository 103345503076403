.info {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: 8px;
  min-width: 0;
}

button.info {
  border-radius: 8px;

  &>div {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 2px solid var(--stroke-focus);
  }
  :global(.usingKeyboard) &:focus {
    box-shadow: 0 0 0 2px var(--stroke-focus);
  }
}

.avatar {
  align-self: flex-start;
  margin-top: 2px;
}

.textWrap {
  min-width: 0;
}

.name {
  max-height: 32px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.nameInOneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text-gray);
}

.employee {
  display: flex;
  flex-flow: column nowrap;
  color: var(--text-gray);

  &.gap:not(:empty) {
    margin-top: 8px;
  }

  p {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .heavy {
    font-weight: 500;
    color: var(--text-black);
  }

  .offset {
    margin-top: 4px;
  }
}
