.signup {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: calc(100% - 48px);
  max-width: 388px;
  padding: 40px 32px;
  background: var(--background-white);
  border-radius: 8px;
  box-sizing: border-box;
}

.buttons {
  display: flex;
  column-gap: 8px;
}
