.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  width: 100%;
  max-width: 338px;
}

.button {
  flex-shrink: 0;
}

.actionButtonLoading {
	color: var(--text-black);
	animation: circle 1s infinite linear;
}

@keyframes circle {
	100% { transform: rotate(360deg); }
}