* {
  margin: 0;
  border: none;
  -webkit-overflow-scrolling: auto;
}

article, aside, figure, footer, header, nav, main, section, details, summary {
  display: block;
}
body, p, ul, li, ol, pre, caption, h1, h2, h3, h4, h5, h6, form, div, fieldset, legend {
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
html.ios-scroll-fixed {
  height: 100vh;
  backface-visibility: hidden;
}

body {
  display: block;
  position: relative;
  min-height: 100%;
  height: 100%;
  width: 100%;
  font: 400 .625em var(--font);
  color: var(--text-black);
  background: var(--background-light-gray);
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar;

  &.scroll-locked {
    overflow: hidden;
  }
  &.scroll-locked-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

html:not(.is-ready-to-scroll) body {
  //position: fixed;
  //top: 0;
  //left: 0;
  //width: 100%;
  //height: 100%;
  //overflow-x: hidden;
  //overflow-y: scroll;
}

html:not(.is-loaded) * {
  //transition: none !important;
}

html {
  &::-webkit-scrollbar{
    display:none;
  }

  & {
    -ms-overflow-style:none;
    scrollbar-width:none;
  }
}

img, fieldset, iframe {
  border: 0;
}

li {
  list-style: none outside none;
}

a,
button {
  text-decoration: none;
  transition:
      color var(--trans),
      background var(--trans),
      border var(--trans),
      opacity var(--trans);

  svg {
    pointer-events: inherit;
  }
}

button {
  display: inline-flex;
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  user-select: none;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
  }
}

[fill] {
  transition: fill var(--trans);
}
[stroke] {
  transition: stroke var(--trans);
}

input,
textarea {
  box-sizing: border-box;
  outline: none;
}

input:required,
textarea:required {
  box-shadow: none;
}

input:invalid,
textarea:invalid {
  box-shadow: 0 0 0 transparent;
}

button,
textarea,

input[type="text"],
input[type="search"],
input[type="password"] {
  -webkit-appearance: none;
}

input::-moz-focus-inner,
textarea::-moz-focus-inner,
select::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition:
    color 5000s ease-in-out 0s,
    background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color:
    var(--text-black) !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
  -webkit-appearance:none;
}

/* * * Prefers reduced all animations and transitions * * */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
