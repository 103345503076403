.box {
  position: relative;
  width: 362px;
  border-radius: inherit;
}

.body {
  position: relative;
  padding: 12px 12px;
  background: var(--background-white);
}

.fieldset {
  & ~ & {
    margin-top: 16px;
  }

  .legend {
    margin-bottom: 8px;
  }
}

/*
 * Паенель с кнопками
 */
.buttons {
  padding: 12px;
  margin-top: -8px;
}
