.smPlusModal {
  padding: 40px 32px 24px 32px;
}

.block {
  padding-top: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-divider);

  & + & {
    padding-top: 16px;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border: none;
  }
}

.performersWrap {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-right: 3px;
}

.participants {
  display: flex;
  column-gap: 8px;
}

.blockLast {
  border: none;
  padding-bottom: 40px;
}

.colorsWrap {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  margin-top: 12px;

  &>div {
    width: 100%;
  }
}

.boardColors {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  gap: 16px;
}

.board {
  position: relative;
  height: auto;
  min-height: 116px;
  border-radius: 8px;
  background: var(--background-white);
  overflow: hidden;

  p {
    span {
      &:first-of-type {
        top: 29px;
        width: 130px;
      }

      &:nth-of-type(2) {
        top: 39px;
        width: 104px;
      }

      &:nth-of-type(3) {
        top: 49px;
        width: 104px;
      }
    }
  }

  &>span {
    bottom: 6px;
  }
}

.boardHead {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 21px;
}

.boardRow {
  position: absolute;
  left: 10px;
  width: 80px;
  height: 7px;
  background: var(--background-gray);
  border-radius: 8px;
}

.btnWrap {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  &>button {
    width: 100%;
  }
}
