.comment {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
  width: 100%;

  &__main {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    min-width: calc(100% - 40px);
  }
}

.wasUpdated {
  display: flex;
  align-items: center;
  column-gap: 2px;
  padding-left: 8px;
  color: var(--text-gray);

  svg {
    height: 12px;
    width: 12px;
    stroke: currentColor;
  }
}
