.participant {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.info {
  display: flex;
  align-items: center;
  column-gap: 8px;
}