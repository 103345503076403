.component {
	position: relative;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	scrollbar-width: none;
	backface-visibility: hidden;
	padding: 0 24px 24px;
	box-sizing: border-box;

	&::-webkit-scrollbar {
		display: none;
	}

	&.x {
		overflow-x: auto;
		overflow-y: hidden;
	}
	&.y {
		overflow-x: hidden;
		overflow-y: auto;
	}
	&.both {
		overflow-x: auto;
		overflow-y: auto;
	}

	:global {
		/*
		 * Вертикальный скролл-бар
		 */
		.ps__rail-y {
			width: 8px;
			font-size: 8px;
			left: auto !important;
			right: 0;
			margin-right: 8px;
			background: none !important;
			overflow: hidden;
			box-sizing: border-box;
			height: calc(100% - 24px) !important;
			position: absolute;
			border-radius: 8px;
			background: var(--border-divider) !important;
			z-index: 5001;
			outline: none;
		}

		.ps__thumb-y {
			right: 0;
			width: 8px;
			opacity: 1;
			background: var(--text-gray);
			border-radius: 8px;
			overflow: hidden;
			transition: background-color .4s;
			outline: none;

			&:focus-visible {
				background: var(--text-black);
			}
			:global(.usingKeyboard) &:focus {
				background: var(--text-black);
			}
		}

		.ps__rail-y:hover > .ps__thumb-y,
		.ps__rail-y:focus > .ps__thumb-y,
		.ps__rail-y.ps--clicking .ps__thumb-y {
			opacity: 1;
			width: 8px;
			background: var(--text-gray);
		}
		/*
		 * Горизонтальный скролл-бар
		 */
		.ps__rail-x {
			height: 8px;
			font-size: 8px;
			top: auto !important;
			width: calc(100% - 48px) !important;
			margin: 0 24px 8px;
			background: var(--border-divider) !important;
			border-radius: 8px;
			box-sizing: border-box;
			overflow: hidden;
			z-index: 5001;
			outline: none;
		}

		.ps__thumb-x {
			bottom: 0;
			height: 8px;
			opacity: 1;
			overflow: hidden;
			background: transparent!important;
			outline: none;

			&::after {
				content: '';
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				border-radius: 8px;
				background: var(--text-gray);
				transition: background-color .4s;
			}

			&:focus-visible::after {
				background: var(--text-black);
			}
			:global(.usingKeyboard) &:focus::after {
				background: var(--text-black);
			}
		}

		.ps__rail-x:hover > .ps__thumb-x,
		.ps__rail-x:focus > .ps__thumb-x,
		.ps__rail-x.ps--clicking .ps__thumb-x {
			opacity: 1;
			height: 1em;
			background: var(--text-gray);
		}
	}

	&:hover :global(.ps__rail-x),
	&:hover :global(.ps__rail-y),

	&:global(.ps--focus > .ps__rail-x),
	&:global(.ps--focus > .ps__rail-y),

	&:global(.ps--scrolling-x > .ps__rail-x),
	&:global(.ps--scrolling-y > .ps__rail-y) {
		opacity: 1 !important;
	}
}
