@import "src/shared/lib/styles/config/mixins";

.board {
  height: 186px;
  border-radius: 8px;
  background: var(--background-white);
  color: var(--text-black);
  position: relative;

  &.isDisabled {
    pointer-events: none;
  }
}

.emptyBoard,
.boardBody {
  transition: box-shadow .4s ease;

  &:hover {
    box-shadow: var(--shadow-card);
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: var(--shadow-card);
  }
  :global(.usingKeyboard) &:focus {
    box-shadow: var(--shadow-card);
  }

  &:focus-within {
    box-shadow: var(--shadow-card);
  }
}

.emptyBoard {
  display: flex;
  align-items: center;
  justify-content: center;
  font: 500 var(--fs-14) var(--font);
  order: 4;
  transition: color .4s ease, box-shadow .4s ease;

  &:hover {
    color: var(--text-green);
  }

  &:focus-visible {
    outline: 2px solid var(--stroke-focus);
    color: var(--text-green);
  }
  :global(.usingKeyboard) &:focus {
    box-shadow: 0 0 0 2px var(--stroke-focus);
    color: var(--text-green);
  }
}

.fullBoard {
  text-align: left;
}

.boardLink {
  &:focus {
    outline: none;
  }
  &:focus-visible {
    .boardBody::after {
      opacity: 1;
    }
  }
  :global(.usingKeyboard) &:focus {
    .boardBody::after {
      opacity: 1;
    }
  }
}

.boardBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px 12px 12px;
  box-sizing: border-box;
  z-index: 1;
  border-radius: 8px;

  &:focus-visible {
    outline: 2px solid var(--stroke-focus);
  }
  :global(.usingKeyboard) &:focus {
    box-shadow: 0 0 0 2px var(--stroke-focus);
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 32px;
    background: var(--tag-green);
    border-radius: 8px 8px 0 0;
  }

  &::after {
    @include absoluteFit;
    box-shadow: inset 0 0 0 2px var(--stroke-focus);
    border-radius: inherit;
    user-select: none;
    pointer-events: none;
    transition:
      opacity var(--trans-fast);
    opacity: 0;
    content: '';
  }
}

.name {
  color: var(--text-black);
  font: 500 var(--fs-14) var(--font);
  margin-bottom: 6px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.time {
  color: var(--text-gray);
  font: 400 var(--fs-13) var(--font);
  margin-top: auto;
  padding-right: 62px;
}

/*
 * Доска в архиве
 */
.boardArchived {
  &:hover .archiveButtons {
    opacity: 1;
  }

  .name {
    -webkit-line-clamp: 5;
  }

  .time {
    padding-right: 0;
  }
}

.archiveButtons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 2;
  padding: 12px;
  gap: 8px;
  outline: none;
  opacity: 0;
  transition: opacity .4s ease;
  background: inherit;

  button {
    width: 100%;
  }

  .keyboard & {
    opacity: 1;
  }
}

/*
 * Кнопка "Избранное"
 */
.starBtn {
  overflow: visible;

  button {
    width: 24px;
    height: 24px;

    &.isActive {
      & > svg {
        fill: currentColor;
      }
    }
  }
}

.starButton:disabled {
  color: var(--text-black);
}

/*
 * Меню управления
 */
.boardTools {
  display: flex;
  justify-content: flex-end;
  column-gap: 4px;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 8px;
  opacity: 0;
  transition:
    opacity var(--trans-fast);
}

.boardToolsMenu {
  position: relative;
  opacity: 0;
  margin-right: -28px;
  pointer-events: none;
  transition:
    margin var(--trans-fast),
    opacity var(--trans-fast);
}

.boardToolsMenuBtn {
  width: 24px;
  height: 24px;
}

.boardToolsMenuDrop {
  min-width: 304px;
  background: var(--background-white);
  border-radius: 8px;
  box-sizing: border-box;
}

/*
 * Неактивное, но находится в избранном
 */
.boardTools.isStarred {
  opacity: 1;
}

/*
 * Активное состояние
 */
@mixin showBoardTools {
  opacity: 1;

  .boardToolsMenu {
    opacity: 1;
    margin-right: 0;
    pointer-events: auto;
  }
}

.board:hover .boardTools {
  @include showBoardTools;
}

.board:focus-visible .boardTools {
  @include showBoardTools;
}
:global(.usingKeyboard) .board:focus .boardTools {
  @include showBoardTools;
}

.boardTools:focus-within {
  @include showBoardTools;
}

.board .boardTools.isOpen,
.board .boardTools.isFocused {
  @include showBoardTools;
}

/*
 * Цвета шапки
 */
.Green::before {
  background: var(--tag-green);
}

.Red::before {
  background: var(--tag-red);
}

.Orange::before {
  background: var(--tag-orange);
}

.Yellow::before {
  background: var(--tag-yellow);
}

.Purple::before {
  background: var(--tag-purple);
}

.Pink::before {
  background: var(--tag-pink);
}

.Blue::before {
  background: var(--tag-blue);
}

.LightBlue::before {
  background: var(--tag-light-blue);
}

.Grey::before {
  background: var(--tag-grey);
}
