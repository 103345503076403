@import "src/shared/lib/styles/config/typography";

.role {
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  transition:
    color .4s,
    background .4s;

  &:hover,
  &:active {
    background: var(--background-gray);
  }
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--stroke-focus);
  }
  :global(.usingKeyboard) &:focus {
    box-shadow: 0 0 0 2px var(--stroke-focus);
  }

  &[disabled] {
    pointer-events: none;
  }
}

.roleDescription {
  @include text-13-regular;
  color: var(--text-black);
  line-height: 20px;
}

.iconCheck {
  width: 12px;
  color: var(--text-green);
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    stroke: currentColor;
  }
}
