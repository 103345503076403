@import "src/shared/lib/styles/config/typography";

.date_title {
  @include text-13-medium;
  margin-bottom: 8px;
}

.group_inputs {
  position: relative;
  border-radius: 6px;
  border: 1px solid var(--border-divider);
  display: flex;

  label:nth-child(2) {
    &::after {
      position: absolute;
      top: 4px;
      left: 0;
      content: '';
      width: 1px;
      height: 16px;
      background-color: var(--border-divider);
    }
  }

  &:focus-within {
    border: 1px solid var(--background-green);
  }

  input {
    border: none;

    &:focus {
      border: none;
    }
  }
}
